<template>
  <div class="pattern-block object tutorial" :class="`size-3`">
    <div class="description-box">
      <span>{{ steps[step].description }}</span>
    </div>
    <div v-if="steps[step].countDown" class="time-box">
      <div :style="gradient" class="time">
        <span>{{ steps[step].countDown }}</span>
      </div>
    </div>
    <div v-for="row in 3" :key="`row${row}`" class="row">
      <div v-for="column in 3" :key="`column${column}`" class="column object">
        <div
          class="item"
          :class="[
            {
              mark: isMarked(row, column),
            },
            {
              fail: isFail(row, column),
            },
          ]"
        >
          <img
            v-if="isAnswerObject(row, column)"
            class="image-object"
            :src="require(`../../assets/image/object.png`)"
          />
        </div>
      </div>
    </div>

    <div class="start-game row">
      <div v-if="steps[step].object.show > 0" class="column object">
        <div class="item" :class="{ fail: steps[step].object.select.fail }">
          <img
            v-if="steps[step].object.select > 0"
            class="image-object"
            :src="require(`../../assets/image/object.png`)"
          />
        </div>
        <div class="text">
          {{ steps[step].object.select }}/{{ steps[step].object.total }}
        </div>
      </div>
      <div v-else class="w-100">
        <el-button v-if="steps[step].countDown" type="primary">{{
          $t("test.btnStartGame")
        }}</el-button>
      </div>
    </div>

    <div class="control-step">
      <div>
        <el-button
          @click="backStep"
          v-if="step > 0 && step < steps.length - 1"
          type="text"
          ><i class="fas fa-angle-left"></i>
          {{ $t("tutorial.btnBack") }}</el-button
        >
      </div>
      <div v-if="step < steps.length - 1">
        <el-button @click="$emit('exitTutorial')" type="text"
          >{{ $t("tutorial.btnSkip") }} <i class="fas fa-angle-right"></i
          ><i class="fas fa-angle-right"></i
        ></el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button @click="nextStep" type="text"
          >{{ $t("tutorial.btnNext") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
      <div v-else>
        <el-button @click="$emit('exitTutorial')" type="text"
          >{{ $t("test.btnStartGame") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pattern: {
      type: Object,
      default: null,
    },
  },
  computed: {
    gradient() {
      return {
        background: `conic-gradient(#FF9B57 0%, #FF9B57 ${
          this.steps[this.step].countDown * 10
        }%, #FFDAC1 ${this.steps[this.step].countDown * 10}%, #FFDAC1 0%)`,
      };
    },
  },
  data() {
    return {
      step: 0,
      steps: [
        {
          step: 0,
          positions: [],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step0"),
          countDown: 10,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          object: {
            fail: false,
            select: 0,
            total: 0,
            show: false,
          },
        },
        {
          step: 1,
          positions: [
            { row: 1, column: 1, object: false },
            { row: 2, column: 3, object: false },
            { row: 3, column: 1, object: false },
          ],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step1"),
          countDown: 7,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          object: {
            fail: false,
            select: 0,
            total: 0,
            show: false,
          },
        },
        {
          step: 2,
          positions: [
            { row: 1, column: 1, object: false },
            { row: 2, column: 3, object: false },
            { row: 3, column: 1, object: false },
          ],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step2"),
          countDown: 2,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          object: {
            fail: false,
            select: 0,
            total: 0,
            show: false,
          },
        },
        {
          step: 3,
          positions: [
            { row: 1, column: 1, object: false },
            { row: 2, column: 3, object: false },
            { row: 3, column: 1, object: false },
          ],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step3"),
          countDown: 1,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          object: {
            fail: false,
            select: 0,
            total: 0,
            show: false,
          },
        },
        {
          step: 4,
          positions: [],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step4"),
          countDown: null,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          object: {
            fail: false,
            select: 0,
            total: 0,
            show: true,
          },
        },
        {
          step: 5,
          positions: [],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step5"),
          countDown: null,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          object: {
            fail: false,
            select: 0,
            total: 1,
            show: true,
          },
        },
        {
          step: 6,
          positions: [],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step6"),
          countDown: null,
          lifePoints: 2,
          fail: {
            row: 1,
            column: 2,
          },
          object: {
            fail: false,
            select: 0,
            total: 1,
            show: true,
          },
        },
        {
          step: 7,
          positions: [
            { row: 1, column: 1, object: false },
            { row: 2, column: 3, object: true },
          ],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step7"),
          countDown: null,
          lifePoints: 2,
          fail: {
            row: null,
            column: null,
          },
          object: {
            fail: false,
            select: 0,
            total: 1,
            show: true,
          },
        },
        {
          step: 8,
          positions: [
            { row: 1, column: 1, object: false },
            { row: 2, column: 3, object: false },
          ],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step8"),
          countDown: null,
          lifePoints: 2,
          fail: {
            row: null,
            column: null,
          },
          object: {
            fail: false,
            select: 1,
            total: 1,
            show: true,
          },
        },
        {
          step: 9,
          positions: [
            { row: 1, column: 1, object: false },
            { row: 2, column: 3, object: true },
          ],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step9"),
          countDown: null,
          lifePoints: 1,
          fail: {
            row: 3,
            column: 1,
          },
          object: {
            fail: false,
            select: 0,
            total: 1,
            show: true,
          },
        },
        {
          step: 10,
          positions: [
            { row: 1, column: 1, object: false },
            { row: 2, column: 3, object: false },
          ],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step10"),
          countDown: null,
          lifePoints: 0,
          fail: {
            row: 3,
            column: 2,
          },
          object: {
            fail: false,
            select: 1,
            total: 1,
            show: true,
          },
        },
        {
          step: 11,
          positions: [
            { row: 1, column: 2, object: false },
            { row: 2, column: 1, object: false },
            { row: 3, column: 3, object: false },
          ],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step11"),
          countDown: 10,
          lifePoints: 1,
          fail: {
            row: null,
            column: null,
          },
          object: {
            fail: false,
            select: 0,
            total: 0,
            show: false,
          },
        },
        {
          step: 12,
          positions: [],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step12"),
          countDown: 3,
          lifePoints: 1,
          fail: {
            row: null,
            column: null,
          },
          object: {
            fail: false,
            select: 0,
            total: 1,
            show: false,
          },
        },
        {
          step: 13,
          positions: [
            { row: 1, column: 2, object: false },
            { row: 2, column: 1, object: false },
            { row: 3, column: 3, object: false },
          ],
          description: this.$t("tutorialSpatialSpanWorkingMemory.step13"),
          countDown: null,
          lifePoints: 2,
          fail: {
            row: null,
            column: null,
          },
          object: {
            fail: false,
            select: 1,
            total: 1,
            show: true,
          },
        },
      ],
    };
  },
  methods: {
    nextStep() {
      this.step++;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    backStep() {
      this.step--;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    skip() {
      this.$emit("exitTutorial");
    },
    isMarked(row, column) {
      let find = this.steps[this.step].positions.find(
        (f) => f.row == row && f.column == column
      );
      return typeof find !== "undefined" ? true : false;
    },
    isAnswerObject(row, column) {
      let find = this.steps[this.step].positions.find(
        (f) => f.row == row && f.column == column && f.object
      );
      return typeof find !== "undefined" ? true : false;
    },
    isFail(row, column) {
      if (
        this.steps[this.step].fail.row == row &&
        this.steps[this.step].fail.column == column &&
        !this.steps[this.step].fail.correct
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  @media screen and (max-width: 1600px) {
    height: 70vh;
  }

  @media screen and (max-width: 1280px) {
    height: 68vh;
  }

  @media screen and (max-width: 1199px) {
    height: 67vh;
  }

  @media screen and (max-width: 1100px) {
    height: 76vh;
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 150px);
  }
}
</style>