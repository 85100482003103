var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pattern-block tutorial",class:[
    "size-1",
    { 'image-show': _vm.steps[_vm.step].showQ && !_vm.steps[_vm.step].playAudio },
    { 'play-audio': _vm.steps[_vm.step].playAudio } ]},[_c('div',{staticClass:"description-box"},[_c('span',[_vm._v(_vm._s(_vm.steps[_vm.step].description))])]),(_vm.steps[_vm.step].showQ && !_vm.steps[_vm.step].playAudio)?_c('img',{staticClass:"image-title",attrs:{"src":require("../../assets/image/image-title2.png")}}):_vm._e(),(_vm.steps[_vm.step].showQ && _vm.steps[_vm.step].playAudio)?_c('img',{staticClass:"image-play",attrs:{"src":require("../../assets/image/play.png")}}):_vm._e(),(!_vm.steps[_vm.step].playAudio)?_c('div',{staticClass:"play-container"},[(_vm.steps[_vm.step].showQ)?_c('div',[(_vm.steps[_vm.step].playAudio)?_c('img',{attrs:{"src":require("../../assets/image/sound.gif")}}):_c('div',{staticClass:"play"},[_c('img',{staticClass:"image-speak",attrs:{"src":require("../../assets/image/speak.png")}}),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("tutorialBeepCode.pressToPlay")))])])]):_c('div',{staticClass:"answer"},[_c('div',{staticClass:"title-box"},[_c('p',{staticClass:"text-1"},[_vm._v(_vm._s(_vm.$t("tutorialBeepCode.pressYourAnswer")))])]),_c('div',{staticClass:"screen",class:{
          error: _vm.steps[_vm.step].correct === false,
          success: _vm.steps[_vm.step].correct === true,
        }},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"box"},_vm._l((_vm.steps[_vm.step].answer),function(item,index){return _c('div',{key:index,staticClass:"beep",class:item === 'S' ? 'type-1' : 'type-2'},[_c('span',[_vm._v(_vm._s(index + 1))])])}),0)])]),_c('div',{staticClass:"action"},[_c('el-button',{attrs:{"round":""}},[_c('div',{staticClass:"beep type-1"})]),_c('el-button',{attrs:{"round":""}},[_c('div',{staticClass:"beep type-2"})])],1),_c('div',{staticClass:"action-answer"},[_c('el-button',{class:{
            'not-active': _vm.steps[_vm.step].answer.length === 0,
          },attrs:{"plain":"","round":""}},[_vm._v(_vm._s(_vm.$t("test.btnSendReset")))]),_c('el-button',{class:{
            'not-active': _vm.steps[_vm.step].answer.length === 0,
          },attrs:{"type":"primary","round":""}},[_vm._v(_vm._s(_vm.$t("test.btnSendAnswer")))])],1)])]):_vm._e(),_c('div',{staticClass:"control-step"},[_c('div',[(_vm.step > 0 && _vm.step < _vm.steps.length - 1)?_c('el-button',{attrs:{"type":"text","disabled":_vm.steps[_vm.step].playAudio},on:{"click":_vm.backStep}},[_c('i',{staticClass:"fas fa-angle-left"}),_vm._v(" "+_vm._s(_vm.$t("tutorial.btnBack")))]):_vm._e()],1),(_vm.step < _vm.steps.length - 1)?_c('div',[_c('el-button',{attrs:{"type":"text","disabled":_vm.steps[_vm.step].playAudio},on:{"click":function($event){return _vm.$emit('exitTutorial')}}},[_vm._v(_vm._s(_vm.$t("tutorial.btnSkip"))+" "),_c('i',{staticClass:"fas fa-angle-right"}),_c('i',{staticClass:"fas fa-angle-right"})]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('el-button',{attrs:{"type":"text","disabled":_vm.steps[_vm.step].playAudio},on:{"click":_vm.nextStep}},[_vm._v(_vm._s(_vm.$t("tutorial.btnNext"))+" "),_c('i',{staticClass:"fas fa-angle-right"})])],1):_c('div',[_c('el-button',{attrs:{"type":"text","disabled":_vm.steps[_vm.step].playAudio},on:{"click":function($event){return _vm.$emit('exitTutorial')}}},[_vm._v(_vm._s(_vm.$t("test.btnStartGame"))+" "),_c('i',{staticClass:"fas fa-angle-right"})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }