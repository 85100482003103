<template>
  <div>
    <DashboardTemplateCasual
      v-if="!fullscreen"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :title="$t('message.titleHome')"
    >
      <div v-if="test" class="start-test">
        <div
          v-if="
            surveyType == 'casual' &&
            (user.type === 'super-admin' || user.type === 'admin')
          "
          class="mode"
        >
          <el-select v-model="mode" placeholder="Mode" @change="changeMode">
            <el-option :label="$t('test.optionMode1')" :value="1"> </el-option>
            <el-option :label="$t('test.optionMode2')" :value="2"> </el-option>
          </el-select>
        </div>
        <h1 v-if="$i18n.locale === 'th'">แบบทดสอบ {{ test.name }}</h1>
        <h1 v-else>{{ test.name }} Test</h1>
        <div class="msg-box">
          <p class="label">{{ $t("test.type") }}</p>
          <p class="msg">{{ test.typeTest }}</p>
        </div>
        <div class="msg-box">
          <p class="label">{{ $t("test.result") }}</p>
          <p class="msg">
            {{ $i18n.locale === "th" ? test.resultTest : test.resultTestEN }}
          </p>
        </div>
        <div class="msg-box">
          <p class="label">{{ $t("test.description") }}</p>
          <p class="msg">
            {{
              $i18n.locale === "th" ? test.introduction : test.introductionEN
            }}
          </p>
        </div>

        <el-tabs
          v-if="test.code === 'word-recognition'"
          v-model="optionTypeWord"
          type="card"
          @tab-click="setTestsMe"
        >
          <el-tab-pane label="Thai" name="th">
            <el-button @click="openTutorial" :disabled="loading" type="info">{{
              $t("test.btnHowto")
            }}</el-button>

            <el-button
              @click="startTest(playableLevel)"
              :disabled="loading || lockCasual(test)"
              type="primary"
              >{{ $t("test.btnStartTest") }}</el-button
            >

            <Level
              :totalLevel="totalLevel"
              :topLevel="topLevel"
              :playableLevel="playableLevel"
              :lock="lockCasual(test)"
              @startTest="startTest"
            />
          </el-tab-pane>
          <el-tab-pane label="English" name="en">
            <el-button @click="openTutorial" :disabled="loading" type="info">{{
              $t("test.btnHowto")
            }}</el-button>

            <el-button
              @click="startTest(playableLevel)"
              :disabled="loading"
              type="primary"
              >{{ $t("test.btnStartTest") }}</el-button
            >
            <Level
              :totalLevel="totalLevel"
              :topLevel="topLevel"
              :playableLevel="playableLevel"
              :lock="lockCasual(test)"
              @startTest="startTest"
            />
          </el-tab-pane>
        </el-tabs>

        <div v-else>
          <el-button @click="openTutorial" :disabled="loading" type="info">{{
            $t("test.btnHowto")
          }}</el-button>

          <el-button
            @click="startTest(playableLevel)"
            :disabled="loading || lockCasual(test)"
            type="primary"
            >{{ $t("test.btnStartTest") }}</el-button
          >
          <Level
            :totalLevel="totalLevel"
            :topLevel="topLevel"
            :playableLevel="playableLevel"
            :lock="lockCasual(test)"
            @startTest="startTest"
          />
        </div>
      </div>
    </DashboardTemplateCasual>

    <fullscreen
      v-model="fullscreen"
      @change="fullscreenChange"
      :page-only="pageOnly"
    >
      <div @click="clickScreen" class="fullscreen-box">
        <div v-if="fullscreen" class="logo">
          <img :src="require(`../assets/logo/logo-white.png`)" />
        </div>

        <div v-if="!endGame && fullscreen" class="status-box">
          <div class="tag">
            <div class="item">
              <font-awesome-icon icon="fa-solid fa-heart" /> {{ lifePointsNow }}
            </div>
          </div>
          <div class="percent">
            <p v-if="test.code === 'word-recognition'">
              {{ this.optionTypeWord === "th" ? "เลเวล" : "LEVEL" }}
              {{ userState.level }}
            </p>
            <p v-else>{{ $t("test.level") }} {{ userState.level }}</p>
          </div>
          <div class="close">
            <font-awesome-icon
              @click="fullscreen = false"
              icon="fa-solid fa-xmark"
            />
          </div>
        </div>

        <div v-if="fullscreen && tutorial" class="fullscreen-wrapper tutorial">
          <div class="w-100">
            <SpatialSpanTutorial
              v-if="test.code === 'spatial-span'"
              @exitTutorial="exitTutorial"
              @setLifePoints="setLifePoints"
            />
            <SpatialSpanSequenceTutorial
              v-if="test.code === 'spatial-span-sequence'"
              @exitTutorial="exitTutorial"
              @setLifePoints="setLifePoints"
            />
            <SpatialSpanColorSequenceTutorial
              v-if="test.code === 'spatial-span-color-sequence'"
              @exitTutorial="exitTutorial"
              @setLifePoints="setLifePoints"
            />
            <SpatialSpanWorkingMemoryTutorial
              v-if="test.code === 'spatial-span-working-memory'"
              @exitTutorial="exitTutorial"
              @setLifePoints="setLifePoints"
            />
            <NumberSequencesTutorial
              v-if="test.code === 'number-sequences'"
              @exitTutorial="exitTutorial"
              @setLifePoints="setLifePoints"
            />
            <PairassociateslearningTutorial
              v-if="test.code === 'pair-associates-learning'"
              @exitTutorial="exitTutorial"
              @setLifePoints="setLifePoints"
            />
            <DigitSpanForwardTutorial
              v-if="test.code === 'digit-span–forward'"
              :optionType="optionType"
              @exitTutorial="exitTutorial"
              @setLifePoints="setLifePoints"
            />
            <BeepCodeTutorial
              v-if="test.code === 'beep-code'"
              @exitTutorial="exitTutorial"
              @setLifePoints="setLifePoints"
            />
            <WordRecognitionTutorial
              v-if="test.code === 'word-recognition'"
              :optionTypeWord="optionTypeWord"
              @exitTutorial="exitTutorial"
              @setLifePoints="setLifePoints"
            />
            <ColorSpanTutorial
              v-if="test.code === 'color-span'"
              :optionTypeWord="optionTypeWord"
              @exitTutorial="exitTutorial"
              @setLifePoints="setLifePoints"
            />
            <DigitSpanBackwardTutorial
              v-if="test.code === 'digit-span–backward'"
              :optionType="optionType"
              @exitTutorial="exitTutorial"
              @setLifePoints="setLifePoints"
            />
          </div>
        </div>
        <div v-if="fullscreen && !tutorial" class="fullscreen-wrapper">
          <div v-if="!endGame" class="w-100">
            <!-- <el-button
            @click="fullscreen = false"
            class="exit-game"
            icon="el-icon-close"
            >ออกจากแบบทดสอบ</el-button
          > -->

            <SpatialSpan
              v-if="test.code === 'spatial-span'"
              :pattern="getTestPattern(userState.level)"
              :lifePointsNow="lifePointsNow"
              @goToLevel="goToLevel"
              @setLogActions="setLogActions"
              @setAnswers="setAnswers"
              @setLifePoints="setLifePoints"
            />

            <SpatialSpanSequence
              v-else-if="test.code === 'spatial-span-sequence'"
              :pattern="getTestPattern(userState.level)"
              :lifePointsNow="lifePointsNow"
              @setLifePoints="setLifePoints"
              @goToLevel="goToLevel"
              @setLogActions="setLogActions"
              @setAnswers="setAnswers"
            />

            <SpatialSpanColorSequence
              v-else-if="test.code === 'spatial-span-color-sequence'"
              :pattern="getTestPattern(userState.level)"
              :lifePointsNow="lifePointsNow"
              @setLifePoints="setLifePoints"
              @goToLevel="goToLevel"
              @setLogActions="setLogActions"
              @setAnswers="setAnswers"
            />

            <SpatialSpanWorkingMemory
              v-else-if="test.code === 'spatial-span-working-memory'"
              :pattern="getTestPattern(userState.level)"
              :lifePointsNow="lifePointsNow"
              @setLifePoints="setLifePoints"
              @goToLevel="goToLevel"
              @setLogActions="setLogActions"
              @setAnswers="setAnswers"
            />

            <NumberSequences
              v-else-if="test.code === 'number-sequences'"
              :pattern="getTestPattern(userState.level)"
              :lifePointsNow="lifePointsNow"
              @setLifePoints="setLifePoints"
              @goToLevel="goToLevel"
              @setLogActions="setLogActions"
              @setAnswers="setAnswers"
            />

            <Pairassociateslearning
              v-if="test.code === 'pair-associates-learning'"
              :pattern="getTestPattern(userState.level)"
              :lifePointsNow="lifePointsNow"
              @setLifePoints="setLifePoints"
              @goToLevel="goToLevel"
              @setLogActions="setLogActions"
              @setAnswers="setAnswers"
            />

            <DigitSpanForward
              v-if="test.code === 'digit-span–forward'"
              :pattern="getTestPattern(userState.level)"
              :lifePointsNow="lifePointsNow"
              @setLifePoints="setLifePoints"
              :optionType="optionType"
              @goToLevel="goToLevel"
              @setLogActions="setLogActions"
              @setAnswers="setAnswersNumber"
            />

            <DigitSpanBackward
              v-if="test.code === 'digit-span–backward'"
              :pattern="getTestPattern(userState.level)"
              :lifePointsNow="lifePointsNow"
              @setLifePoints="setLifePoints"
              :optionType="optionType"
              @goToLevel="goToLevel"
              @setLogActions="setLogActions"
              @setAnswers="setAnswersNumber"
            />

            <WordRecognition
              v-if="test.code === 'word-recognition'"
              :pattern="getTestPattern(userState.level)"
              :lifePointsNow="lifePointsNow"
              :optionTypeWord="optionTypeWord"
              @setLifePoints="setLifePoints"
              @goToLevel="goToLevel"
              @setLogActions="setLogActions"
              @setAnswers="setAnswersNumber"
            />

            <BeepCode
              v-if="test.code === 'beep-code'"
              :pattern="getTestPattern(userState.level)"
              :lifePointsNow="lifePointsNow"
              @setLifePoints="setLifePoints"
              @goToLevel="goToLevel"
              @setLogActions="setLogActions"
              @setAnswers="setAnswersNumber"
            />

            <ColorSpan
              v-if="test.code === 'color-span'"
              :pattern="getTestPattern(userState.level)"
              :lifePointsNow="lifePointsNow"
              @setLifePoints="setLifePoints"
              @goToLevel="goToLevel"
              @setLogActions="setLogActions"
              @setAnswers="setAnswersNumber"
            />
          </div>

          <div v-else class="end-game">
            <h1 class="title">{{ $t("test.endGameTitle") }}</h1>
            <p class="text-level">
              {{ $t("test.endGameLevel") }}
              {{
                userState.level === testPatterns.length
                  ? userState.level
                  : userState.level - 1
              }}
            </p>
            <el-button
              v-if="saveFail"
              @click="
                saveTest(
                  userState.level === testPatterns.length
                    ? userState.level
                    : userState.level - 1
                )
              "
              type="info"
              :loading="loading"
              icon="el-icon-refresh-left"
            >
              {{ $t("test.endGameSaveAgain") }}</el-button
            >
            <el-button v-else @click="changeFullScreen(false)" type="danger">{{
              $t("test.endGameExit")
            }}</el-button>
          </div>
        </div>
      </div>

      <el-dialog
        :title="$t('test.lockScreenTitle')"
        :visible.sync="lockScreenDialogVisible"
        :close-on-click-modal="false"
        :show-close="false"
        width="100%"
        :fullscreen="true"
        class="lock-screen"
      >
        <span>{{ $t("test.lockScreenVertically") }}</span>
      </el-dialog>
    </fullscreen>
  </div>
</template>

<script>
import DashboardTemplateCasual from "@/template/DashboardTemplateCasual";
import Level from "@/components/level/Level";

import SpatialSpan from "@/components/test/SpatialSpan";
import SpatialSpanSequence from "@/components/test/SpatialSpanSequence";
import SpatialSpanColorSequence from "@/components/test/SpatialSpanColorSequence";
import SpatialSpanWorkingMemory from "@/components/test/SpatialSpanWorkingMemory";
import NumberSequences from "@/components/test/NumberSequences";
import Pairassociateslearning from "@/components/test/Pairassociateslearning";
import DigitSpanForward from "@/components/test/DigitSpanForward";
import BeepCode from "@/components/test/BeepCode";
import WordRecognition from "@/components/test/WordRecognition";
import ColorSpan from "@/components/test/ColorSpan";
import DigitSpanBackward from "@/components/test/DigitSpanBackward";

import SpatialSpanTutorial from "@/components/tutorial/SpatialSpanTutorial";
import SpatialSpanSequenceTutorial from "@/components/tutorial/SpatialSpanSequenceTutorial";
import SpatialSpanColorSequenceTutorial from "@/components/tutorial/SpatialSpanColorSequenceTutorial";
import SpatialSpanWorkingMemoryTutorial from "@/components/tutorial/SpatialSpanWorkingMemoryTutorial";
import NumberSequencesTutorial from "@/components/tutorial/NumberSequencesTutorial";
import PairassociateslearningTutorial from "@/components/tutorial/PairassociateslearningTutorial";
import DigitSpanForwardTutorial from "@/components/tutorial/DigitSpanForwardTutorial";
import BeepCodeTutorial from "@/components/tutorial/BeepCodeTutorial";
import WordRecognitionTutorial from "@/components/tutorial/WordRecognitionTutorial";
import ColorSpanTutorial from "@/components/tutorial/ColorSpanTutorial";
import DigitSpanBackwardTutorial from "@/components/tutorial/DigitSpanBackwardTutorial";

// import json  from "@/components/test/testData.json";

import { HTTP, HTTP_WEB_MAIN } from "@/service/axios";

export default {
  components: {
    DashboardTemplateCasual,
    Level,
    SpatialSpan,
    SpatialSpanSequence,
    SpatialSpanColorSequence,
    SpatialSpanWorkingMemory,
    NumberSequences,
    Pairassociateslearning,
    DigitSpanForward,
    BeepCode,
    WordRecognition,
    ColorSpan,
    DigitSpanBackward,

    SpatialSpanTutorial,
    SpatialSpanColorSequenceTutorial,
    SpatialSpanSequenceTutorial,
    SpatialSpanWorkingMemoryTutorial,
    NumberSequencesTutorial,
    PairassociateslearningTutorial,
    DigitSpanForwardTutorial,
    BeepCodeTutorial,
    WordRecognitionTutorial,
    ColorSpanTutorial,
    DigitSpanBackwardTutorial,
  },
  computed: {
    modeStore() {
      return this.$store.state.mode;
    },
    surveyType() {
      return this.$store.state.surveyType;
    },
    disconnect() {
      return this.$store.state.disconnect;
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      fullscreen: false,
      userState: {
        level: 1,
      },
      testPatterns: [],
      endGame: false,
      loading: true,
      answers: [],
      saveFail: true,

      test: null,
      tutorial: false,
      totalLevel: 0,
      topLevel: 1,
      playableLevel: 1,
      mode: 1,
      startTime: null,
      startLevel: 1,
      optionType: "forward",
      optionTypeWord: "th",
      resultTopLevel: null,
      lifePointsNow: 3,
      idleTime: 0,
      myIntervalIdle: null,
      lockScreenDialogVisible: false,
      pageOnly: false,
      logActions: [],
      numallClicks: 0,
      sessionId: "",
      totalTest: 0,
    };
  },
  watch: {
    modeStore() {
      this.mode = this.modeStore;
    },
    onLine(v) {
      if (v && this.user) {
        this.showBackOnline = true;
      } else {
        this.showBackOnline = false;
      }
    },
    isIdle(idle) {
      if (idle && this.fullscreen && !this.tutorial && !this.endGame) {
        this.myIntervalIdle = setInterval(() => {
          this.idleTime++;
        }, 1000);
      } else {
        this.idleTime = 0;
        clearInterval(this.myIntervalIdle);
      }
    },
    idleTime(sec) {
      if (sec >= 60 * 5) {
        this.fullscreen = false;
        this.idleTime = 0;
        clearInterval(this.myIntervalIdle);
      }
    },
  },
  mounted() {
    if (this.$device.ios && this.$device.ipad) {
      this.pageOnly = true;
    }

    window.addEventListener("resize", this.orientationchange);
    this.optionTypeWord = this.$i18n.locale;

    this.fetchTestPeriod();
    this.fetchTestsMe();
    this.mode = this.modeStore;
    onkeyup = (event) => {
      switch (event.which) {
        case 44: // PrintScreen
          this.fullscreen = false;
          break;
      }
    };
  },
  destroyed() {
    this.$store.commit("SET_BREADCRUMBS", []);
  },
  methods: {
    clickScreen() {
      this.numallClicks++;
    },
    orientationchange() {
      if (window.innerHeight <= 500) {
        this.lockScreenDialogVisible = true;
      } else {
        this.lockScreenDialogVisible = false;
      }
    },
    async fetchTestPeriod() {
      try {
        let res = await HTTP.get(`/log/test/periods/by/${this.surveyType}`);

        if (res.data.success) {
          this.sessionId = res.data.result.sessionId;
          this.$store.commit("SET_STEPS", res.data.result.steps);
          if (typeof res.data.result.disconnect !== "undefined") {
            this.$store.commit("SET_DISCONNECT", res.data.result.disconnect);
          }
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    changeMode() {
      this.$store.commit("SET_MODE", this.mode);
    },
    fullscreenChange(status) {
      this.fullscreen = status;
      if (!status && !this.tutorial) {
        if (this.lifePointsNow > 0) {
          this.updateLogActions(this.userState.level, false);
        }

        if (this.surveyType == "period") {
          this.onDisconnect();
        } else {
          this.lifePointsNow = 3;
          this.$router.push(`/test/${this.$route.params.id}`);
        }

        this.answers = [];
        this.fetchTestsMe();
      }
    },
    async setTestsMe() {
      if (this.test.code === "word-recognition") {
        if (this.optionTypeWord === "th") {
          this.topLevel =
            this.resultTopLevel.th == 0 ? 1 : this.resultTopLevel.th;
        } else {
          this.topLevel =
            this.resultTopLevel.en == 0 ? 1 : this.resultTopLevel.en;
        }
      }

      if (this.topLevel == 1 || this.topLevel == 2) {
        this.playableLevel = 1;
      } else {
        this.playableLevel = this.topLevel - 2;
      }
    },
    async fetchTestsMe() {
      try {
        let path = `/tests/me/${this.$route.params.id}`;

        if (this.surveyType == "period") {
          path += "?periodAllow=yes";
        }

        let res = await HTTP.get(path);

        if (res.data.success) {
          this.test = res.data.result.test;
          this.totalTest = res.data.result.totalTest;
          this.totalLevel = res.data.result.totalLevel;
          if (this.surveyType == "casual") {
            if (this.test.code === "word-recognition") {
              this.resultTopLevel = res.data.result.topLevel;

              if (this.optionTypeWord === "th") {
                this.topLevel =
                  res.data.result.topLevel.th == 0
                    ? 1
                    : res.data.result.topLevel.th;
              } else if (this.optionTypeWord === "en") {
                this.topLevel =
                  res.data.result.topLevel.en == 0
                    ? 1
                    : res.data.result.topLevel.en;
              }
            } else {
              this.topLevel =
                res.data.result.topLevel == 0 ? 1 : res.data.result.topLevel;
            }

            if (this.topLevel == 1 || this.topLevel == 2) {
              this.playableLevel = 1;
            } else {
              this.playableLevel = this.topLevel - 2;
            }
          } else {
            if (this.disconnect) {
              this.topLevel =
                this.disconnect.userState.level < 1
                  ? 1
                  : this.disconnect.userState.level;

              this.testPatterns = this.disconnect.testPatterns;

              this.answers = this.disconnect.answers;

              this.changeFullScreen(true, this.disconnect.userState.level);
              this.startTime = new Date();
              this.startLevel = this.disconnect.userState.level;
              this.lifePointsNow = this.disconnect.lifePoints;
            }
          }
          this.$store.commit("SET_BREADCRUMBS", [
            { path: "/", name: "home", i18n: true },
            { path: null, name: this.test.name },
          ]);
        } else {
          this.totalLevel = 0;
          this.topLevel = 1;
        }
      } catch (e) {
        const error = e.response;
        console.error(error);
        this.totalLevel = 1;
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
    async getTestsRandom(level) {
      try {
        this.loading = true;
        let obj = {
          testId: this.$route.params.id,
          start: level,
          test: this.mode == 1 ? false : true,
        };
        let res = await HTTP.post(`/tests/random/patterns`, obj);

        if (res.data.success) {
          this.testPatterns = res.data.result.rows;
          return true;
        } else {
          this.$message({
            message: "เกิดข้อผิดพลาดกรุณาลองใหม่",
            type: "error",
            duration: 1000,
            onClose: () => {
              this.$router.push(`/`);
            },
          });
          return false;
        }
      } catch (e) {
        // const error = e.response;
        // if (error.data.message === "level: not allowed") {
        //   this.$message({
        //     message: "คุณยังไม่เคยผ่านถึงเลเวลนี้",
        //     type: "error",
        //     duration: 1000,
        //     onClose: () => {
        //       this.$router.push(`/level/${this.$route.params.id}`);
        //     },
        //   });
        // }
        console.error(e);
        return false;
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
    async saveTest(topLevel) {
      if (this.answers.length > 0) {
        this.loading = true;
        try {
          let obj = {
            testId: this.$route.params.id,
            topLevel: topLevel,
            answers: this.answers,
            type:
              this.test.code === "word-recognition" ? this.optionTypeWord : "",
          };

          if (this.modeStore == 1) {
            await HTTP.post(`/log/tests`, obj);
            this.addDatatoWebMain(topLevel);
          }

          let answers = this.answers.filter(
            (filter) =>
              filter.actions.find((find) => find.correct === false) ===
              undefined
          );

          let time = 0;
          answers.map((item) => {
            time += item.time;
          });

          const timeAVG = time / answers.length;

          this.updateTestPeriod(topLevel, timeAVG);
          this.updateLogActions(topLevel, true);

          if (this.surveyType == "casual") {
            this.fetchTestsMe();
          }
          this.saveFail = false;

          this.answers = [];
        } catch (e) {
          const error = e.response;
          this.$message({
            message: "บันทึกล้มเหลว กรุณาลองใหม่",
            type: "error",
            duration: 1000,
          });
          this.saveFail = true;
          console.error(error.data.message);
        } finally {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        }
      }
    },
    async updateTestPeriod(topLevel, timeAVG) {
      try {
        let steps = this.$store.state.steps;
        let index = -1;
        let indexEN = -1;
        let indexTH = -1;
        if (this.test.code === "word-recognition") {
          indexEN = steps.findIndex(
            (f) => f.testId === this.test._id && f.key === "test9en"
          );
          indexTH = steps.findIndex(
            (f) => f.testId === this.test._id && f.key === "test9th"
          );
        } else {
          index = steps.findIndex((f) => f.testId === this.test._id);
        }

        if (index > -1) {
          let scores = this.getDataScore(topLevel, timeAVG);
          scores.map((item) => {
            steps[index] = item;
          });
        } else if (indexEN > -1 && indexTH > -1) {
          let scores = this.getDataScore(topLevel, timeAVG);

          steps[indexEN] = scores[0];
          steps[indexTH] = scores[1];
        } else {
          let scores = this.getDataScore(topLevel, timeAVG);

          scores.map((item) => {
            steps.push(item);
          });
        }

        let obj = {
          steps: steps,
          surveyType: this.surveyType,
        };

        if (steps.length > this.totalTest && this.surveyType == "period") {
          obj["endTime"] = new Date();
        } else if (steps.length >= 3 && this.surveyType == "casual") {
          obj["endTime"] = new Date();
        }

        if (this.test.code === "word-recognition") {
          obj["lang"] = this.optionTypeWord === "en" ? "en-US" : "th";
        }

        let res = await HTTP.put(`/log/test/periods`, obj);

        if (res.data.success) {
          if (this.surveyType == "period") {
            if (steps.length > this.totalTest) {
              window.open(res.data.result.url);
            } else {
              this.$router.push("/");
            }
          } else {
            this.fetchTestPeriod();
          }
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    getDataScore(topLevel, timeAVG) {
      let data = [];
      if (this.test.code === "spatial-span") {
        data = [
          {
            testId: this.test._id,
            key: "test1",
            level: topLevel,
            time: timeAVG,
          },
        ];
      } else if (this.test.code === "spatial-span-sequence") {
        data = [
          {
            testId: this.test._id,
            key: "test2",
            level: topLevel,
            time: timeAVG,
          },
        ];
      } else if (this.test.code === "pair-associates-learning") {
        data = [
          {
            testId: this.test._id,
            key: "test6",
            level: topLevel,
            time: timeAVG,
          },
        ];
      } else if (this.test.code === "digit-span–forward") {
        data = [
          {
            testId: this.test._id,
            key: "test7f",
            level: topLevel,
            time: timeAVG,
          },
        ];
      } else if (this.test.code === "digit-span–backward") {
        data = [
          {
            testId: this.test._id,
            key: "test7b",
            level: topLevel,
            time: timeAVG,
          },
        ];
      } else if (this.test.code === "word-recognition") {
        let topLevelEN = null;
        let topLevelTH = null;

        let timeEN = null;
        let timeTH = null;

        if (this.optionTypeWord === "en") {
          topLevelEN = topLevel;
          timeEN = timeAVG;
        } else {
          topLevelTH = topLevel;
          timeTH = timeAVG;
        }
        data = [
          {
            testId: this.test._id,
            key: "test9en",
            level: topLevelEN,
            time: timeEN,
          },
          {
            testId: this.test._id,
            key: "test9th",
            level: topLevelTH,
            time: timeTH,
          },
        ];
      } else if (this.test.code === "spatial-span-color-sequence") {
        data = [
          {
            testId: this.test._id,
            key: "test3",
            level: topLevel,
            time: timeAVG,
          },
        ];
      } else if (this.test.code === "spatial-span-working-memory") {
        data = [
          {
            testId: this.test._id,
            key: "test4",
            level: topLevel,
            time: timeAVG,
          },
        ];
      } else if (this.test.code === "number-sequences") {
        data = [
          {
            testId: this.test._id,
            key: "test5",
            level: topLevel,
            time: timeAVG,
          },
        ];
      } else if (this.test.code === "beep-code") {
        data = [
          {
            testId: this.test._id,
            key: "test8",
            level: topLevel,
            time: timeAVG,
          },
        ];
      } else if (this.test.code === "color-span") {
        data = [
          {
            testId: this.test._id,
            key: "test10",
            level: topLevel,
            time: timeAVG,
          },
        ];
      }

      return data;
    },
    async addDatatoWebMain(topLevel) {
      let obj = {
        user_id: this.user.id,
        test_id: this.$route.params.id,
        test_version: 1,
        time_start: this.startTime,
        time_finish: new Date(),
        level_start: this.startLevel,
        level_achieved: topLevel,
      };

      await HTTP_WEB_MAIN.post(`/cognitive/log/test`, obj);
    },
    changeFullScreen(status, level) {
      this.userState = {
        level: level,
      };

      this.endGame = false;
      this.tutorial = false;
      this.fullscreen = status;
    },
    async openTutorial() {
      this.fullscreen = true;
      this.tutorial = true;
      let random = await this.getTestsRandom(this.playableLevel);

      if (!random) {
        this.fullscreen = false;
      }
    },
    async startTest(level) {
      this.fullscreen = true;
      let random = await this.getTestsRandom(level);
      if (random) {
        this.changeFullScreen(true, level);
        this.startTime = new Date();
        this.startLevel = level;
      } else {
        this.fullscreen = false;
      }

      return true;
    },
    exitTutorial() {
      if (!this.lockCasual(this.test)) {
        this.setLifePoints(3);
        this.fullscreen = false;
        setTimeout(() => {
          this.tutorial = false;
        }, 500);
        this.changeFullScreen(true, this.playableLevel);
      } else {
        this.fullscreen = false;
      }
    },
    getTestPattern(level) {
      let pattern = this.testPatterns.find((f) => f.level == Number(level));

      if (typeof pattern !== "undefined") {
        return pattern;
      } else {
        return null;
      }
    },
    getPercent(level) {
      return (level / this.totalLevel) * 100;
    },
    setAnswers(answer) {
      let index = this.answers.findIndex(
        (f) =>
          f.patternId === answer.patternId && f.optionId === answer.optionId
      );
      let obj = {
        row: answer.row,
        column: answer.column,
        correct: answer.correct,
      };

      if (typeof answer.stimul !== "undefined") {
        obj = {
          number: answer.number,
          stimul: answer.stimul,
          correct: answer.correct,
        };
      }

      if (typeof answer.color !== "undefined") {
        obj.color = answer.color;
      }

      if (typeof answer.answerObject !== "undefined") {
        obj.answerObject = answer.answerObject;
      }

      if (typeof answer.number !== "undefined") {
        obj.number = answer.number;
      }

      if (index > -1) {
        this.answers[index].time = this.answers[index].time + answer.time;
        this.answers[index].actions.push(obj);
      } else {
        this.answers.push({
          patternId: answer.patternId,
          optionId: answer.optionId,
          time: answer.time,
          actions: [obj],
        });
      }
    },
    setAnswersNumber(answer) {
      let index = this.answers.findIndex(
        (f) =>
          f.patternId === answer.patternId && f.optionId === answer.optionId
      );
      let obj = {
        answer: answer.answer,
        correct: answer.correct,
      };

      if (index > -1) {
        this.answers[index].time = this.answers[index].time + answer.time;
        this.answers[index].actions.push(obj);
      } else {
        this.answers.push({
          patternId: answer.patternId,
          optionId: answer.optionId,
          time: answer.time,
          actions: [obj],
        });
      }
    },
    goToLevel(status) {
      if (status === "success") {
        if (this.userState.level === this.testPatterns.length) {
          this.saveTest(this.userState.level);
          this.endGame = true;
        } else {
          this.userState.level++;
        }
      } else {
        this.endGame = true;
        this.saveTest(this.userState.level - 1);
      }
    },
    getTimePeriod(s) {
      let min = parseInt(s / 60);

      let sec = s - min * 60;

      if (sec > 0) {
        return `${min}.${sec} นาที`;
      } else {
        return `${min} นาที`;
      }
    },
    async onDisconnect() {
      try {
        let obj = {
          surveyType: this.surveyType,
          disconnect: {
            testPatterns: this.testPatterns,
            userState: this.userState,
            answers: this.answers,
            lifePoints: this.lifePointsNow,
          },
        };

        let res = await HTTP.put(`/log/test/periods/disconnect`, obj);

        if (res.data.success) {
          if (res.data.result.overDisconnect) {
            this.updateTestPeriod(null, null);
          } else {
            this.$router.push(`/home`);
          }
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    setLifePoints(life) {
      this.lifePointsNow = life;
    },
    setLogActions(data) {
      let obj = {
        patternId: data.patternId,
        size: data.patternSize,
        level: this.userState.level,
        trials: data.trials,
        itemId: data.itemId,
        answers: data.answers,
        correctAnswers: data.correctAnswers,
        isCorrect: data.isCorrect,
        timeStartStimuli: data.timeStartStimuli,
        endStimuli: data.endStimuli,
        timeStartResponse: data.timeStartResponse,
        timeFirstClick: data.timeFirstClick,
        timeLastResponse: data.timeLastResponse,
        numResponseClicks: data.numResponseClicks,
        numallClicks: this.numallClicks,
        durationStimuli:
          (new Date(data.timeStartResponse).getTime() -
            new Date(data.timeStartStimuli).getTime()) /
          1000,
        durationResponse:
          (new Date(data.timeLastResponse).getTime() -
            new Date(data.timeStartResponse).getTime()) /
          1000,
        isPassTheLevel: data.isPassTheLevel,
        responseAnswers:
          typeof data.responseAnswers !== "undefined"
            ? data.responseAnswers
            : null,
        lang: typeof data.lang !== "undefined" ? data.lang : "",
        activeColors:
          typeof data.activeColors !== "undefined" ? data.activeColors : [],

        collectAnswers:
          typeof data.collectAnswers !== "undefined" ? data.collectAnswers : [],
        timeStart:
          typeof data.timeStart !== "undefined" ? data.timeStart : null,
        durationStart:
          typeof data.timeStart !== "undefined"
            ? (new Date(data.timeStartStimuli).getTime() -
                new Date(data.timeStart).getTime()) /
              1000
            : null,
      };
      this.numallClicks = 0;

      this.logActions.push(obj);

      if (data.isPassTheLevel) {
        this.logActions = this.logActions.map((item) => {
          item.isPassTheLevel = data.isPassTheLevel;
          return item;
        });
      }
    },
    async updateLogActions(maxLevel, gameSessionFinished) {
      try {
        let gameSessionId = `${new Date().getTime()}${this.user.id}`;
        let actions = [];

        this.updatePatternStatistics(this.logActions);
        this.logActions.map((item) => {
          actions.push({
            patternId: item.patternId,
            level: item.level,
            trials: item.trials,
            itemId: item.itemId,
            answers: item.answers,
            correctAnswers: item.correctAnswers,
            isCorrect: item.isCorrect,
            timeStartStimuli: item.timeStartStimuli,
            endStimuli: item.endStimuli,
            timeStartResponse: item.timeStartResponse,
            timeFirstClick: item.timeFirstClick,
            timeLastResponse: item.timeLastResponse,
            numResponseClicks: item.numResponseClicks,
            numallClicks: item.numallClicks,
            durationStimuli: item.durationStimuli,
            durationResponse: item.durationResponse,
            isPassTheLevel: item.isPassTheLevel,
            size: item.size,
            responseAnswers: item.responseAnswers,
            lang: item.lang,
            activeColors: item.activeColors,
            collectAnswers: item.collectAnswers,
            timeStart: item.timeStart,
            durationStart: item.durationStart,
          });
        });

        let obj = {
          testId: this.test._id,
          sessionId: this.sessionId,
          sessionType: this.surveyType == "period" ? "Period" : "Casual",
          gameSessionFinished: gameSessionFinished,
          maxLevel: maxLevel,
          gameSessionId: gameSessionId,
          actions: actions,
        };

        await HTTP.post(`/log/actions`, obj);

        this.logActions = [];
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async updatePatternStatistics(logActions) {
      try {
        let statistics = [];
        let inPatternId = [];
        logActions.map((item) => {
          statistics.push({
            patternId: item.patternId,
            itemId: item.itemId,
            totalUse: 1,
            totalPass: item.isCorrect ? 1 : 0,
            durationResponse: item.durationResponse,
            totalLevelPass: item.isCorrect && item.isPassTheLevel ? 1 : 0,
          });

          inPatternId.push(item.patternId);
        });

        let obj = {
          statistics: statistics,
          inPatternId: inPatternId,
          sessionType: this.surveyType == "period" ? "Period" : "Casual",
        };

        await HTTP.put(`/patterns/statistics`, obj);
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/element-variables.scss";

.start-test {
  padding: 30px 5vw;
  text-align: center;
  .mode {
    text-align: end;
  }
  h1 {
    font-size: 28px;
  }

  .msg-box {
    text-align: left;
    .label {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }
    .msg {
      font-size: 16px;
      margin-top: 5px;
      color: $--color-gray-60;
    }
    .form {
      margin-top: 10px;
      .el-radio {
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    h1 {
      font-size: 26px;
    }

    .msg-box {
      text-align: left;
      .label {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
      .msg {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 10px 2vw;
    h1 {
      font-size: 20px;
    }

    .msg-box {
      text-align: left;
      .label {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
      .msg {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
  button {
    margin-top: 20px;
  }
}

.fullscreen-box {
  background-image: url("../assets/image/bg-game.png");
  background-size: cover;
  background-color: $--color-blue-65;
  text-align: center;
  padding: 0 15vw;
  overflow-y: auto;
  height: 100%;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 918px) {
    padding: 0 10vw;
  }

  .logo {
    padding: 20px 0;
    img {
      height: 72px;
    }
  }

  .status-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .tag {
      width: 200px;
      text-align: center;
      .item {
        width: 60px;

        background: #ff768d;
        border-radius: 12px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        padding: 12px 5px;
      }
    }

    .percent {
      flex: 1;
      p {
        font-size: 18px;
        color: #fff;
      }
    }

    .close {
      width: 200px;
      color: #fff;
      font-size: 30px;
      text-align: right;
      svg {
        cursor: pointer;
      }
    }

    @media screen and (max-width: 1024px) {
      .tag,
      .close {
        width: 150px;
      }
    }

    @media screen and (max-width: 500px) {
      .tag {
        width: 80px;
        .item {
          width: 50px;
          font-size: 14px;
          padding: 10px 2px;
        }
      }

      .percent {
        width: 100%;
        p {
          font-size: 14px;
        }
      }

      .close {
        width: 80px;
        font-size: 18px;
        text-align: center;
      }
    }
  }
  .fullscreen-wrapper {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .end-game {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 70vh;
      .title {
        color: #fff;
        margin: 0;
        font-size: 28px;
      }
      .text-level {
        padding: 10px 0;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .logo {
      display: none;
    }
    .status-box {
      padding-top: 20px;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 0 15px;
    .logo {
      display: none;
    }
    .status-box {
      padding-top: 20px;
    }
  }
}

.pattern-block {
  .title {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    span {
      font-weight: 600;
    }
    .life-points {
      i {
        margin: 0 2px;
        font-size: 12px;
      }
    }
  }
  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      background-color: green;
      width: 100px;
      height: 100px;
      margin: 10px;
    }
  }
}
</style>
