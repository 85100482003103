<template>
  <div>
    <div
      v-if="countDownStart > 3 && pattern"
      class="pattern-block"
      :class="`size-${pattern.size}`"
    >
      <div v-if="showLock" class="lock-box">
        <div class="lock">
          <font-awesome-icon icon="fa-solid fa-lock " />
        </div>
      </div>
      <div v-for="row in pattern.size" :key="`row${row}`" class="row">
        <div
          v-for="column in pattern.size"
          :key="`column${column}`"
          class="column"
        >
          <div
            @click="reload ? null : touchBlock(row, column)"
            class="item"
            :class="[
              {
                mark: isMarked(row, column),
              },
              {
                marked: isAnswer(row, column) && !delayClcik,
              },
              {
                fail: isFail(row, column) && !delayClcik,
              },
              {
                disabled: reload,
              },
            ]"
          ></div>
        </div>
      </div>
    </div>
    <div v-else class="pattern-block">
      <CircleCountDown :countDownStart="countDownStart" />
    </div>
  </div>
</template>

<script>
import CircleCountDown from "@/components/game/CircleCountDown";

export default {
  components: {
    CircleCountDown,
  },
  props: {
    pattern: {
      type: Object,
      default: null,
    },
    lifePointsNow: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    pattern() {
      if (!this.gameMounted) {
        this.startGame(3);
      }
    },
    lifePoints() {
      this.$emit("setLifePoints", this.lifePoints);
    },
  },
  data() {
    return {
      answers: [
        {
          row: null,
          column: null,
          correct: false,
          dateTime: null,
        },
      ],
      saveAnswers: [],
      lifePoints: 3,
      reload: true,
      showAnswer: null,
      indexAnswer: 0,
      delayClcik: false,
      startRound: null,
      countDownStart: 0,
      gameMounted: true,
      showLock: false,
      timeStartStimuli: null,
      timeStartResponse: null,
      timeFirstClick: null,
      timeLastResponse: null,
      numResponseClicks: 0,
    };
  },
  mounted() {
    var countDownIntervalIdle = setInterval(() => {
      this.countDownStart++;
    }, 1000);

    setTimeout(() => {
      setTimeout(() => {
        clearInterval(countDownIntervalIdle);
      }, 1000);
      this.startGame(this.lifePointsNow);
      this.gameMounted = false;
    }, 4000);
  },
  methods: {
    startGame(lefe) {
      this.timeStartStimuli = new Date();
      this.numResponseClicks = 0;
      this.timeFirstClick = null;
      this.reload = true;

      this.answers = [
        {
          row: null,
          column: null,
          correct: false,
        },
      ];
      this.saveAnswers = [];
      this.lifePoints = lefe;

      this.pattern.options[3 - this.lifePoints].positions.map((item, index) => {
        setTimeout(() => {
          this.showAnswer = null;
          this.showLock = true;
          setTimeout(() => {
            this.showAnswer = item;
          }, 400);
        }, 1200 * index);
      });
      setTimeout(() => {
        this.showAnswer = null;
        this.startRound = Date.now();
        this.reload = false;
        this.showLock = false;
        this.timeStartResponse = new Date();
      }, this.pattern.options[3 - this.lifePoints].positions.length * 1200);
    },

    isMarked(row, column) {
      if (!this.showAnswer) {
        return false;
      }

      return this.showAnswer.row == row && this.showAnswer.column == column
        ? true
        : false;
    },
    touchBlock(row, column) {
      let indexOption = 3 - this.lifePoints;

      this.numResponseClicks++;
      if (!this.timeFirstClick) {
        this.timeFirstClick = new Date();
      }

      if (
        this.pattern &&
        this.pattern.options[indexOption].positions[this.indexAnswer].row ==
          row &&
        this.pattern.options[indexOption].positions[this.indexAnswer].column ==
          column
      ) {
        this.answers.push({
          row: row,
          column: column,
          correct: true,
          dateTime: new Date(),
        });

        this.saveAnswers.push({
          row: row,
          column: column,
          correct: true,
          dateTime: new Date(),
        });

        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          row: row,
          column: column,
          correct: true,
          time: Date.now() - this.startRound,
        });

        let countSuccess = this.indexAnswer + 1;

        if (countSuccess < this.pattern.options[indexOption].positions.length) {
          this.indexAnswer++;
        } else {
          this.reload = true;

          this.timeLastResponse = new Date();
          this.$emit("setLogActions", {
            patternId: this.pattern._id,
            patternSize: this.pattern.size,
            trials: indexOption + 1,
            itemId: this.pattern.options[indexOption].id,
            answers: this.saveAnswers,
            timeFirstClick: this.timeFirstClick,
            correctAnswers: this.pattern.options[indexOption].positions,
            isCorrect: true,
            timeStartStimuli: this.timeStartStimuli,
            endStimuli: null,
            timeStartResponse: this.timeStartResponse,
            timeLastResponse: this.timeLastResponse,
            numResponseClicks: this.numResponseClicks,
            isPassTheLevel: true,
          });
        }

        setTimeout(() => {
          this.answers.splice(0, this.answers.length - 1);

          if (
            countSuccess == this.pattern.options[indexOption].positions.length
          ) {
            this.indexAnswer = 0;
            setTimeout(() => {
              this.$emit("goToLevel", "success");
              this.reload = false;
            }, 500);
          }
        }, 100);
      } else {
        this.indexAnswer = 0;
        this.reload = true;
        this.lifePoints--;

        this.answers.push({
          row: row,
          column: column,
          correct: false,
          dateTime: new Date(),
        });

        this.saveAnswers.push({
          row: row,
          column: column,
          correct: false,
          dateTime: new Date(),
        });

        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          row: row,
          column: column,
          correct: false,
          time: Date.now() - this.startRound,
        });

        this.timeLastResponse = new Date();

        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: indexOption + 1,
          itemId: this.pattern.options[indexOption].id,
          answers: this.saveAnswers,
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[indexOption].positions,
          isCorrect: false,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: null,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: false,
        });

        setTimeout(() => {
          this.answers.splice(0, this.answers.length - 1);
          if (this.lifePoints <= 0) {
            this.$emit("goToLevel", "fail");
          } else {
            setTimeout(() => {
              this.startGame(this.lifePoints);
            }, 500);
          }
        }, 100);
      }
    },

    isAnswer(row, column) {
      let find = this.answers.find((f) => f.row == row && f.column == column);

      return typeof find !== "undefined" ? true : false;
    },

    isFail(row, column) {
      let find = this.answers.find(
        (f) => f.row == row && f.column == column && !f.correct
      );

      return typeof find !== "undefined" ? true : false;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  height: 74vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;

  @media screen and (max-width: 1600px) {
    height: 70vh;
  }

  @media screen and (max-width: 1280px) {
    height: 67vh;
  }

  @media screen and (max-width: 1199px) {
    height: 67vh;
  }

  @media screen and (max-width: 1100px) {
    height: 74vh;
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 150px);
  }
}
</style>