<template>
  <div>
    <div
      v-if="countDownStart > 3 && pattern"
      class="pattern-block color"
      :class="`size-${pattern.size}`"
    >
      <div class="question-color">
        <div
          v-if="activeColor == 1"
          class="item color-1"
          :class="{ active: activeColor == 1 }"
        >
          Green
        </div>
        <div
          v-if="activeColor == 2"
          class="item color-2"
          :class="{ active: activeColor == 2 }"
        >
          Yellow
        </div>
      </div>
      <div v-for="row in pattern.size" :key="`row${row}`" class="row">
        <div
          v-for="column in pattern.size"
          :key="`column${column}`"
          class="column"
        >
          <div
            @click="reload || !activeColor ? null : touchBlock(row, column)"
            class="item show-color"
            :class="[
              {
                mark: isMarked(row, column),
              },
              {
                marked: isAnswer(row, column) && !delayClcik,
              },
              {
                fail: isFail(row, column) && !delayClcik,
              },
              {
                disabled: reload || !activeColor,
              },
              {
                'color-1': isColor(row, column, 1),
              },
              {
                'color-2': isColor(row, column, 2),
              },
            ]"
          ></div>
        </div>
      </div>
      <div class="action-color">
        <div v-if="activeColor == 1" class="item">Green</div>
        <div v-if="activeColor == 2" class="item">Yellow</div>
      </div>
    </div>
    <div v-else class="pattern-block">
      <CircleCountDown :countDownStart="countDownStart" />
    </div>
  </div>
</template>

<script>
import CircleCountDown from "@/components/game/CircleCountDown";

export default {
  components: {
    CircleCountDown,
  },
  props: {
    pattern: {
      type: Object,
      default: null,
    },
    lifePointsNow: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    pattern() {
      if (!this.gameMounted) {
        this.startGame(3);
      }
    },
    lifePoints() {
      this.$emit("setLifePoints", this.lifePoints);
    },
  },
  data() {
    return {
      answers: [
        {
          row: null,
          column: null,
          correct: false,
        },
      ],
      lifePoints: 3,
      reload: true,
      showAnswer: null,
      indexAnswer: 0,
      delayClcik: false,
      activeColor: null,
      successColors: [],
      startRound: null,
      countDownStart: 0,
      gameMounted: true,
      timeStartStimuli: null,
      timeStartResponse: null,
      timeFirstClick: null,
      timeLastResponse: null,
      numResponseClicks: 0,
      saveAnswers: [],
      saveActiveColors: [],
    };
  },
  mounted() {
    var countDownIntervalIdle = setInterval(() => {
      this.countDownStart++;
    }, 1000);

    setTimeout(() => {
      setTimeout(() => {
        clearInterval(countDownIntervalIdle);
      }, 1000);
      this.startGame(this.lifePointsNow);
      this.gameMounted = false;
    }, 4000);
  },
  methods: {
    startGame(lefe) {
      this.timeStartStimuli = new Date();
      this.numResponseClicks = 0;
      this.timeFirstClick = null;
      this.saveAnswers = [];
      this.saveActiveColors = [];

      this.reload = true;
      let colors = [1, 2];

      let randomColor = colors[Math.floor(Math.random() * colors.length)];

      this.answers = [
        {
          row: null,
          column: null,
          correct: false,
        },
      ];
      this.lifePoints = lefe;

      this.successColors = [];

      this.pattern.options[3 - this.lifePoints].positions.map((item, index) => {
        setTimeout(() => {
          this.showAnswer = null;
          setTimeout(() => {
            this.showAnswer = item;
          }, 500);
        }, 1000 * index);
      });
      this.activeColor = null;

      setTimeout(() => {
        this.showAnswer = null;
        this.startRound = Date.now();
        this.reload = false;
        this.activeColor = randomColor;
        this.timeStartResponse = new Date();

        let randomColor2 = randomColor === 1 ? 2 : 1;
        this.saveActiveColors = [randomColor, randomColor2];
      }, this.pattern.options[3 - this.lifePoints].positions.length * 1000);
    },

    isMarked(row, column) {
      if (!this.showAnswer) {
        return false;
      }

      return this.showAnswer.row == row && this.showAnswer.column == column
        ? true
        : false;
    },
    touchBlock(row, column) {
      let indexOption = 3 - this.lifePoints;

      this.numResponseClicks++;
      if (!this.timeFirstClick) {
        this.timeFirstClick = new Date();
      }

      let positions = this.pattern.options[indexOption].positions.filter(
        (f) => f.color === this.activeColor
      );

      if (
        positions[this.indexAnswer].row == row &&
        positions[this.indexAnswer].column == column
      ) {
        this.answers.push({
          row: row,
          column: column,
          correct: true,
          color: this.activeColor,
          dateTime: new Date(),
        });

        this.saveAnswers.push({
          row: row,
          column: column,
          correct: true,
          color: this.activeColor,
          dateTime: new Date(),
        });

        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          row: row,
          column: column,
          correct: true,
          color: this.activeColor,
          time: Date.now() - this.startRound,
        });

        let countSuccess = this.indexAnswer + 1;

        if (countSuccess < positions.length) {
          this.indexAnswer++;
        } else {
          this.successColors.push(this.activeColor);

          if (this.successColors.length !== 2) {
            setTimeout(() => {
              this.indexAnswer = 0;
              this.answers = [];
              if (this.activeColor == 1) {
                this.activeColor = 2;
              } else {
                this.activeColor = 1;
              }
            }, 500);
          }
        }
        setTimeout(() => {
          this.answers.splice(0, this.answers.length - 1);
          if (this.successColors.length >= 2) {
            this.reload = true;
          }
        }, 100);

        if (this.successColors.length >= 2) {
          this.timeLastResponse = new Date();
          this.$emit("setLogActions", {
            patternId: this.pattern._id,
            patternSize: this.pattern.size,
            trials: indexOption + 1,
            itemId: this.pattern.options[indexOption].id,
            answers: this.saveAnswers,
            timeFirstClick: this.timeFirstClick,
            correctAnswers: this.pattern.options[indexOption].positions,
            isCorrect: true,
            timeStartStimuli: this.timeStartStimuli,
            endStimuli: null,
            timeStartResponse: this.timeStartResponse,
            timeLastResponse: this.timeLastResponse,
            numResponseClicks: this.numResponseClicks,
            isPassTheLevel: true,
            activeColors: this.saveActiveColors,
          });

          setTimeout(() => {
            this.activeColor = null;
            this.indexAnswer = 0;
            this.answers = [];
            this.successColors = [];
            this.$emit("goToLevel", "success");
          }, 600);
        }
      } else {
        this.indexAnswer = 0;
        this.reload = true;
        this.lifePoints--;

        this.answers.push({
          row: row,
          column: column,
          correct: false,
          color: this.activeColor,
          dateTime: new Date(),
        });

        this.saveAnswers.push({
          row: row,
          column: column,
          correct: true,
          color: this.activeColor,
          dateTime: new Date(),
        });

        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          row: row,
          column: column,
          correct: false,
          color: this.activeColor,
          time: Date.now() - this.startRound,
        });

        this.timeLastResponse = new Date();

        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: indexOption + 1,
          itemId: this.pattern.options[indexOption].id,
          answers: this.saveAnswers,
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[indexOption].positions,
          isCorrect: false,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: null,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: false,
          activeColors: this.saveActiveColors,
        });

        setTimeout(() => {
          this.answers.splice(0, this.answers.length - 1);
          if (this.lifePoints <= 0) {
            this.$emit("goToLevel", "fail");
          } else {
            setTimeout(() => {
              this.startGame(this.lifePoints);
            }, 500);
          }
        }, 500);
      }
    },
    isAnswer(row, column) {
      let find = this.answers.find((f) => f.row == row && f.column == column);

      return typeof find !== "undefined" ? true : false;
    },

    isFail(row, column) {
      let find = this.answers.find(
        (f) => f.row == row && f.column == column && !f.correct
      );

      return typeof find !== "undefined" ? true : false;
    },
    isColor(row, column, color) {
      let indexOption = 3 - this.lifePoints;

      if (typeof this.pattern.options[indexOption] !== "undefined") {
        let find = this.pattern.options[indexOption].positions.find(
          (f) => f.row == row && f.column == column && f.color == color
        );

        let answer = this.answers.find(
          (f) => f.row == row && f.column == column
        );

        if (typeof find !== "undefined") {
          if (
            (this.showAnswer && this.showAnswer.color === find.color) ||
            (typeof answer !== "undefined" && answer.color === find.color)
          ) {
            return true;
          }
        }
      }

      return false;
    },

    suceesColor(key) {
      let find = this.successColors.find((f) => f === key);

      return typeof find != "undefined" ? true : false;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 20px;
  height: 76vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1600px) {
    height: 70vh;
  }

  @media screen and (max-width: 1280px) {
    height: 68vh;
  }

  @media screen and (max-width: 1199px) {
    height: 67vh;
  }

  @media screen and (max-width: 1100px) {
    height: 76vh;
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 150px);
  }
}
</style>