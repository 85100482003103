var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.countDownStart > 3 && _vm.pattern)?_c('div',{staticClass:"pattern-block",class:("size-" + (_vm.pattern.size))},[(_vm.showLock)?_c('div',{staticClass:"lock-box"},[_c('div',{staticClass:"lock"},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-lock "}})],1)]):_vm._e(),_vm._l((_vm.pattern.size),function(row){return _c('div',{key:("row" + row),staticClass:"row"},_vm._l((_vm.pattern.size),function(column){return _c('div',{key:("column" + column),staticClass:"column"},[_c('div',{staticClass:"item",class:[
            {
              mark: _vm.isMarked(row, column),
            },
            {
              marked: _vm.isAnswer(row, column) && !_vm.delayClcik,
            },
            {
              fail: _vm.isFail(row, column) && !_vm.delayClcik,
            },
            {
              disabled: _vm.reload,
            } ],on:{"click":function($event){_vm.reload ? null : _vm.touchBlock(row, column)}}})])}),0)})],2):_c('div',{staticClass:"pattern-block"},[_c('CircleCountDown',{attrs:{"countDownStart":_vm.countDownStart}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }