var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pattern-block color tutorial",class:"size-3"},[_c('div',{staticClass:"description-box"},[_c('span',[_vm._v(_vm._s(_vm.steps[_vm.step].description))])]),_c('div',{staticClass:"question-color"},[(_vm.steps[_vm.step].activeColor == 1)?_c('div',{staticClass:"item color-1",class:{ active: _vm.steps[_vm.step].activeColor == 1 }},[_vm._v(" Green ")]):_vm._e(),(_vm.steps[_vm.step].activeColor == 2)?_c('div',{staticClass:"item color-2",class:{ active: _vm.steps[_vm.step].activeColor == 2 }},[_vm._v(" Yellow ")]):_vm._e()]),_vm._l((3),function(row){return _c('div',{key:("row" + row),staticClass:"row"},_vm._l((3),function(column){return _c('div',{key:("column" + column),staticClass:"column"},[_c('div',{staticClass:"item show-color",class:[
          {
            mark: _vm.isMarked(row, column),
          },
          {
            fail: _vm.isFail(row, column),
          },
          {
            'color-1': _vm.isColor(row, column, 1),
          },
          {
            'color-2': _vm.isColor(row, column, 2),
          } ]})])}),0)}),_c('div',{staticClass:"action-color"},[(_vm.steps[_vm.step].activeColor == 1)?_c('div',{staticClass:"item"},[_vm._v("Green")]):_vm._e(),(_vm.steps[_vm.step].activeColor == 2)?_c('div',{staticClass:"item"},[_vm._v("Yellow")]):_vm._e()]),_c('div',{staticClass:"control-step"},[_c('div',[(_vm.step > 0 && _vm.step < _vm.steps.length - 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.backStep}},[_c('i',{staticClass:"fas fa-angle-left"}),_vm._v(" "+_vm._s(_vm.$t("tutorial.btnBack")))]):_vm._e()],1),(_vm.step < _vm.steps.length - 1)?_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$emit('exitTutorial')}}},[_vm._v(" "+_vm._s(_vm.$t("tutorial.btnSkip"))+" "),_c('i',{staticClass:"fas fa-angle-right"}),_c('i',{staticClass:"fas fa-angle-right"})]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.$t("tutorial.btnNext"))+" "),_c('i',{staticClass:"fas fa-angle-right"})])],1):_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$emit('exitTutorial')}}},[_vm._v(_vm._s(_vm.$t("test.btnStartGame"))+" "),_c('i',{staticClass:"fas fa-angle-right"})])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }