<template>
  <div class="pattern-block color tutorial" :class="`size-3`">
    <div class="description-box">
      <span>{{ steps[step].description }}</span>
    </div>
    <div class="question-color">
      <div
        v-if="steps[step].activeColor == 1"
        class="item color-1"
        :class="{ active: steps[step].activeColor == 1 }"
      >
        Green
      </div>
      <div
        v-if="steps[step].activeColor == 2"
        class="item color-2"
        :class="{ active: steps[step].activeColor == 2 }"
      >
        Yellow
      </div>
    </div>

    <div v-for="row in 3" :key="`row${row}`" class="row">
      <div v-for="column in 3" :key="`column${column}`" class="column">
        <div
          class="item show-color"
          :class="[
            {
              mark: isMarked(row, column),
            },
            {
              fail: isFail(row, column),
            },
            {
              'color-1': isColor(row, column, 1),
            },
            {
              'color-2': isColor(row, column, 2),
            },
          ]"
        ></div>
      </div>
    </div>
    <div class="action-color">
      <div v-if="steps[step].activeColor == 1" class="item">Green</div>
      <div v-if="steps[step].activeColor == 2" class="item">Yellow</div>
    </div>

    <div class="control-step">
      <div>
        <el-button
          @click="backStep"
          v-if="step > 0 && step < steps.length - 1"
          type="text"
          ><i class="fas fa-angle-left"></i>
          {{ $t("tutorial.btnBack") }}</el-button
        >
      </div>
      <div v-if="step < steps.length - 1">
        <el-button @click="$emit('exitTutorial')" type="text">
          {{ $t("tutorial.btnSkip") }} <i class="fas fa-angle-right"></i
          ><i class="fas fa-angle-right"></i
        ></el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button @click="nextStep" type="text">
          {{ $t("tutorial.btnNext") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
      <div v-else>
        <el-button @click="$emit('exitTutorial')" type="text"
          >{{ $t("test.btnStartGame") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pattern: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      step: 0,
      steps: [
        {
          step: 0,
          positions: [],
          description: this.$t("tutorialSpatialSpanColorSequence.step0"),
          countDown: null,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          activeColor: null,
        },
        {
          step: 1,
          positions: [],
          description: this.$t("tutorialSpatialSpanColorSequence.step1"),
          countDown: null,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          activeColor: null,
        },
        {
          step: 2,
          positions: [{ row: 1, column: 1, color: 1 }],
          description: this.$t("tutorialSpatialSpanColorSequence.step2"),
          countDown: 10,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          activeColor: null,
        },
        {
          step: 3,
          positions: [{ row: 3, column: 3, color: 2 }],
          description: this.$t("tutorialSpatialSpanColorSequence.step3"),
          countDown: 1,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          activeColor: null,
        },
        {
          step: 4,
          positions: [],
          description: this.$t("tutorialSpatialSpanColorSequence.step4"),
          countDown: null,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          activeColor: 1,
        },
        {
          step: 5,
          positions: [],
          description: this.$t("tutorialSpatialSpanColorSequence.step5"),
          countDown: null,
          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
          activeColor: 1,
        },
        {
          step: 6,
          positions: [],
          description: this.$t("tutorialSpatialSpanColorSequence.step6"),
          countDown: null,
          lifePoints: 2,
          fail: {
            row: 2,
            column: 2,
          },
          activeColor: 1,
        },
        {
          step: 7,
          positions: [],
          description: this.$t("tutorialSpatialSpanColorSequence.step7"),
          countDown: null,
          lifePoints: 0,
          fail: {
            row: 3,
            column: 1,
          },
          activeColor: 1,
        },
        {
          step: 8,
          positions: [{ row: 1, column: 2, color: 1 }],
          description: this.$t("tutorialSpatialSpanColorSequence.step8"),
          countDown: null,
          lifePoints: 2,
          fail: {
            row: null,
            column: null,
          },
          activeColor: null,
        },
        {
          step: 9,
          positions: [{ row: 2, column: 3, color: 2 }],
          description: this.$t("tutorialSpatialSpanColorSequence.step9"),
          countDown: null,
          lifePoints: 2,
          fail: {
            row: null,
            column: null,
          },
          activeColor: null,
        },
        {
          step: 10,
          positions: [{ row: 2, column: 3, color: 2 }],
          description: this.$t("tutorialSpatialSpanColorSequence.step10"),
          countDown: null,
          lifePoints: 2,
          fail: {
            row: null,
            column: null,
          },
          activeColor: 2,
        },
      ],
    };
  },
  methods: {
    nextStep() {
      this.step++;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    backStep() {
      this.step--;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    skip() {
      this.$emit("exitTutorial");
    },
    isMarked(row, column) {
      let find = this.steps[this.step].positions.find(
        (f) => f.row == row && f.column == column
      );
      return typeof find !== "undefined" ? true : false;
    },
    isColor(row, column, color) {
      let find = this.steps[this.step].positions.find(
        (f) => f.row == row && f.column == column && f.color == color
      );
      return typeof find !== "undefined" ? true : false;
    },
    isFail(row, column) {
      if (
        this.steps[this.step].fail.row == row &&
        this.steps[this.step].fail.column == column &&
        !this.steps[this.step].fail.correct
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 20px;
  height: 76vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1600px) {
    height: 70vh;
  }

  @media screen and (max-width: 1280px) {
    height: 68vh;
  }

  @media screen and (max-width: 1199px) {
    height: 67vh;
  }

  @media screen and (max-width: 1100px) {
    height: 76vh;
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 150px);
  }
}
</style>