<template>
  <div
    class="pattern-block tutorial"
    :class="[
      `size-1`,
      { 'image-show': steps[step].showQ && !steps[step].playAudio },
      { 'play-audio': steps[step].playAudio },
    ]"
  >
    <div class="description-box">
      <span>{{ steps[step].description }}</span>
    </div>

    <img
      v-if="steps[step].showQ && !steps[step].playAudio"
      class="image-title"
      :src="require(`../../assets/image/image-title2.png`)"
    />

    <img
      v-if="steps[step].showQ && steps[step].playAudio"
      class="image-play"
      :src="require(`../../assets/image/play.png`)"
    />

    <div v-if="!steps[step].playAudio" class="play-container">
      <div v-if="steps[step].showQ">
        <img
          v-if="steps[step].playAudio"
          :src="require(`../../assets/image/sound.gif`)"
        />
        <div v-else class="play">
          <img
            class="image-speak"
            :src="require(`../../assets/image/speak.png`)"
          />
          <p class="text">{{ $t("tutorialBeepCode.pressToPlay") }}</p>
        </div>
      </div>
      <div v-else class="answer">
        <div class="title-box">
          <p class="text-1">{{ $t("tutorialBeepCode.pressYourAnswer") }}</p>
        </div>

        <div
          class="screen"
          :class="{
            error: steps[step].correct === false,
            success: steps[step].correct === true,
          }"
        >
          <div class="background">
            <div class="box">
              <div
                v-for="(item, index) in steps[step].answer"
                :key="index"
                class="beep"
                :class="item === 'S' ? 'type-1' : 'type-2'"
              >
                <span>{{ index + 1 }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="action">
          <el-button round><div class="beep type-1"></div></el-button>
          <el-button round><div class="beep type-2"></div></el-button>
        </div>
        <div class="action-answer">
          <el-button
            plain
            :class="{
              'not-active': steps[step].answer.length === 0,
            }"
            round
            >{{ $t("test.btnSendReset") }}</el-button
          >

          <el-button
            type="primary"
            :class="{
              'not-active': steps[step].answer.length === 0,
            }"
            round
            >{{ $t("test.btnSendAnswer") }}</el-button
          >
        </div>
      </div>
    </div>

    <div class="control-step">
      <div>
        <el-button
          @click="backStep"
          v-if="step > 0 && step < steps.length - 1"
          type="text"
          :disabled="steps[step].playAudio"
          ><i class="fas fa-angle-left"></i>
          {{ $t("tutorial.btnBack") }}</el-button
        >
      </div>
      <div v-if="step < steps.length - 1">
        <el-button
          @click="$emit('exitTutorial')"
          type="text"
          :disabled="steps[step].playAudio"
          >{{ $t("tutorial.btnSkip") }} <i class="fas fa-angle-right"></i
          ><i class="fas fa-angle-right"></i
        ></el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button
          @click="nextStep"
          type="text"
          :disabled="steps[step].playAudio"
          >{{ $t("tutorial.btnNext") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
      <div v-else>
        <el-button
          @click="$emit('exitTutorial')"
          type="text"
          :disabled="steps[step].playAudio"
          >{{ $t("test.btnStartGame") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 0,
      steps: [
        {
          step: 0,
          description: this.$t("tutorialBeepCode.step0"),
          lifePoints: 3,
          showQ: true,
          playAudio: false,
          correct: null,
          answer: [],
          plays: [],
        },
        {
          step: 1,
          description: this.$t("tutorialBeepCode.step1"),
          lifePoints: 3,
          showQ: true,
          playAudio: true,
          correct: null,
          answer: [],
          plays: ["L", "S"],
        },
        {
          step: 2,
          description: this.$t("tutorialBeepCode.step2"),
          lifePoints: 3,
          showQ: false,
          playAudio: false,
          correct: null,
          answer: [],
          plays: [],
        },
        {
          step: 3,
          description: this.$t("tutorialBeepCode.step3"),
          lifePoints: 3,
          showQ: false,
          playAudio: false,
          correct: null,
          answer: [],
          plays: [],
        },
        {
          step: 4,
          description: this.$t("tutorialBeepCode.step4"),
          lifePoints: 3,

          showQ: false,
          playAudio: false,
          correct: null,
          answer: ["S", "L"],
          plays: [],
        },
        {
          step: 5,
          description: this.$t("tutorialBeepCode.step5"),
          lifePoints: 3,
          showQ: false,
          playAudio: false,
          correct: false,
          answer: ["S", "L"],
          plays: [],
        },
        {
          step: 6,
          description: this.$t("tutorialBeepCode.step6"),
          lifePoints: 0,
          showQ: false,
          playAudio: false,
          correct: false,
          answer: ["L", "L"],
          plays: [],
        },
        {
          step: 7,
          description: this.$t("tutorialBeepCode.step7"),
          lifePoints: 1,
          showQ: true,
          playAudio: false,
          correct: true,
          answer: [],
          plays: [],
        },
        {
          step: 8,
          description: this.$t("tutorialBeepCode.step8"),
          lifePoints: 1,
          showQ: true,
          playAudio: true,
          correct: true,
          answer: [],
          plays: ["S", "L"],
        },
        {
          step: 9,
          description: this.$t("tutorialBeepCode.step9"),
          lifePoints: 1,
          showQ: false,
          playAudio: false,
          correct: true,
          answer: ["S", "L"],
          plays: [],
        },
      ],
    };
  },
  methods: {
    nextStep() {
      this.step++;
      this.playStep();
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    backStep() {
      this.step--;
      this.playStep();
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    skip() {
      this.$emit("exitTutorial");
    },
    playStep() {
      if (this.steps[this.step].plays.length > 0) {
        this.steps[this.step].plays.map((item, index) => {
          setTimeout(() => {
            if (item === "S") {
              this.playBeep1();
            } else {
              this.playBeep2();
            }
          }, 1500 * index);
        });

        setTimeout(() => {
          this.step++;
          this.steps[this.step].playAudio = false;
        }, 3000);
      }
    },
    playBeep1() {
      const audio = new Audio(require(`../../assets/video/censor-beep-1.mp3`));
      audio.play();
    },
    playBeep2() {
      const audio = new Audio(require(`../../assets/video/censor-beep-2.mp3`));
      audio.play();
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 40px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 71vh;
  margin-top: 0;

  &.image-show {
    margin-top: 235px;
    height: 49vh;
  }

  &.play-audio {
    background: transparent;
    .description-box {
      color: #fff;
    }
    .control-step {
      .el-button--text {
        color: #fff;
      }
    }
  }

  .image-title {
    position: absolute;
    top: -225px;
    width: 300px;
    z-index: -1;
  }

  .title-box {
    text-align: left;
    .text-1 {
      font-size: 16px;
      margin: 0;
      padding-bottom: 10px;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1600px) {
    height: 65vh;

    &.image-show {
      margin-top: 215px;
      height: 40vh;
    }

    .image-title {
      top: -220px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 62vh;
    &.image-show {
      margin-top: 185px;
      height: 39vh;
    }

    .image-title {
      top: -190px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 62vh;
    &.image-show {
      margin-top: 185px;
      height: 39vh;
    }

    .image-title {
      top: -190px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: 70vh;
    &.image-show {
      margin-top: 185px;
      height: 44vh;
    }

    .image-title {
      top: -190px;
      width: 250px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 78vh;
    &.image-show {
      margin-top: 220px;
      height: 60vh;
    }

    .image-title {
      top: -215px;
      width: 300px;
    }
  }

  @media screen and (max-width: 800px) {
    height: 77vh;
    &.image-show {
      margin-top: 220px;
      height: 56vh;
    }

    .image-title {
      top: -215px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 115px);
    padding: 10px;

    &.image-show {
      margin-top: 100px;
      height: calc(100vh - 215px);
    }

    .image-title {
      top: -110px;
      width: 150px;
    }
  }
}
</style>