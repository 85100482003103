<template>
  <div
    class="pattern-block tutorial"
    :class="[`size-1`, { 'image-show': steps[step].showQ }]"
  >
    <div class="description-box">
      <span>{{ steps[step].description }}</span>
    </div>

    <div v-if="steps[step].showQ" class="qScreen">
      <div v-if="steps[step].qNumber" class="box">
        {{ steps[step].qNumber }}
      </div>
    </div>
    <div v-else class="numeric-container">
      <div class="numeric-screen">
        <el-input
          v-model="steps[step].number"
          :class="{
            error: steps[step].correct === false,
            success: steps[step].correct === true,
          }"
          readonly
        >
          <font-awesome-icon
            class="el-input__icon hide"
            icon="fa-regular fa-circle-check "
            slot="prefix"
          />
          <font-awesome-icon
            v-if="steps[step].correct === null"
            class="el-input__icon hide"
            icon="fa-regular fa-circle-check"
            slot="suffix"
          />

          <font-awesome-icon
            v-if="steps[step].correct === true"
            icon="fa-regular fa-circle-check"
            class="el-input__icon"
            slot="suffix"
          />
          <font-awesome-icon
            v-if="steps[step].correct === false"
            icon="fa-regular fa-circle-xmark"
            class="el-input__icon"
            slot="suffix"
          />
        </el-input>
      </div>
      <div class="numeric-keypad">
        <div
          v-for="(item, index) in numbers"
          :key="index"
          @click="touchNumber(item)"
          class="numeric-box"
          :class="{ hide: item === '' }"
        >
          <font-awesome-icon v-if="item === 'Reset'" icon="fa-solid fa-trash" />
          <font-awesome-icon
            v-else-if="item === 'Del'"
            icon="fa-solid fa-delete-left"
          />
          <span v-else>
            {{ item }}
          </span>
        </div>
      </div>
      <div class="text-center">
        <el-button
          @click="sendAnswer"
          type="primary"
          :loading="reload"
          class="w-100 button-send-answer"
          round
          >{{ $t("test.btnSendAnswer") }}</el-button
        >
      </div>
    </div>
    <img
      v-if="steps[step].showQ"
      class="image-footer-1"
      :src="require(`../../assets/image/image-footer-1.png`)"
    />

    <div class="control-step">
      <div>
        <el-button
          @click="backStep"
          v-if="step > 0 && step < steps.length - 1"
          type="text"
          ><i class="fas fa-angle-left"></i>
          {{ $t("tutorial.btnBack") }}</el-button
        >
      </div>
      <div v-if="step < steps.length - 1">
        <el-button @click="$emit('exitTutorial')" type="text"
          >{{ $t("tutorial.btnSkip") }} <i class="fas fa-angle-right"></i
          ><i class="fas fa-angle-right"></i
        ></el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button @click="nextStep" type="text"
          >{{ $t("tutorial.btnNext") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
      <div v-else>
        <el-button @click="$emit('exitTutorial')" type="text"
          >{{ $t("test.btnStartGame") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    optionType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, "Reset", 0, "Del"],
      step: 0,
      steps: [
        {
          step: 0,
          description: this.$t("tutorialDigitSpanBackward.step0"),
          lifePoints: 3,
          qNumber: "7",
          number: "",
          correct: null,
          showQ: true,
        },
        {
          step: 1,
          description: this.$t("tutorialDigitSpanBackward.step1"),
          lifePoints: 3,
          qNumber: "7",
          number: "",
          correct: null,
          showQ: true,
        },
        {
          step: 2,
          description: this.$t("tutorialDigitSpanBackward.step2"),
          lifePoints: 3,
          qNumber: "9",
          number: "",
          correct: null,
          showQ: true,
        },
        {
          step: 3,
          description: this.$t("tutorialDigitSpanBackward.step3"),
          lifePoints: 3,
          qNumber: "",
          number: "",
          correct: null,
          showQ: false,
        },
        {
          step: 4,
          description: this.$t("tutorialDigitSpanBackward.step4"),
          lifePoints: 3,
          qNumber: "",
          number: "",
          correct: null,
          showQ: false,
        },
        {
          step: 5,
          description: this.$t("tutorialDigitSpanBackward.step5"),
          lifePoints: 3,
          qNumber: "",
          number: "75",
          correct: null,
          showQ: false,
        },
        {
          step: 6,
          description: this.$t("tutorialDigitSpanBackward.step6"),
          lifePoints: 2,
          qNumber: "",
          number: "75",
          correct: false,
          showQ: false,
        },

        {
          step: 7,
          description: this.$t("tutorialDigitSpanBackward.step7"),
          lifePoints: 0,
          qNumber: "",
          number: "59",
          correct: false,
          showQ: false,
        },
        {
          step: 8,
          description: this.$t("tutorialDigitSpanBackward.step8"),
          lifePoints: 1,
          qNumber: "8",
          number: "",
          correct: true,
          showQ: true,
        },
        {
          step: 9,
          description: this.$t("tutorialDigitSpanBackward.step9"),
          lifePoints: 1,
          qNumber: "5",
          number: "",
          correct: null,
          showQ: true,
        },
        {
          step: 10,
          description: this.$t("tutorialDigitSpanBackward.step10"),
          lifePoints: 1,
          qNumber: "",
          number: "58",
          correct: true,
          showQ: false,
        },
      ],
    };
  },

  methods: {
    nextStep() {
      this.step++;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    backStep() {
      this.step--;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    skip() {
      this.$emit("exitTutorial");
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  margin-top: 0;
  padding: 20px;

  .numeric-container {
    width: 290px;
  }

  &.image-show {
    margin-bottom: 175px;
    height: 59vh;
  }

  .image-footer-1 {
    position: absolute;
    bottom: -203px;
    right: 0;
    width: 400px;
    z-index: 99;
  }

  @media screen and (max-width: 1600px) {
    height: 69vh;

    &.image-show {
      margin-bottom: 175px;
      height: 51vh;
    }

    .image-footer-1 {
      bottom: -184px;
      width: 350px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 69vh;
    .numeric-container {
      width: 250px;
    }
    &.image-show {
      margin-bottom: 125px;
      height: 49vh;
    }

    .image-footer-1 {
      bottom: -168px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: calc(68vh - 30px);

    &.image-show {
      margin-bottom: 125px;
      height: 50vh;
    }

    .image-footer-1 {
      bottom: -170px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: calc(77vh - 30px);
    &.image-show {
      margin-bottom: 125px;
      height: 56vh;
    }

    .image-footer-1 {
      bottom: -164px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: calc(78vh - 30px);
    &.image-show {
      margin-bottom: 125px;
      height: 60vh;
    }

    .image-footer-1 {
      bottom: -164px;
      width: 300px;
    }
  }

  @media screen and (max-width: 918px) {
    height: calc(82vh - 30px);

    &.image-show {
      margin-bottom: 125px;
      height: 74vh;
    }

    .image-footer-1 {
      bottom: -161px;
      width: 300px;
    }
  }

  @media screen and (max-width: 700px) {
    height: 81vh;

    &.image-show {
      margin-bottom: 125px;
      height: 72vh;
    }

    .image-footer-1 {
      bottom: -160px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 115px);
    padding: 10px;

    &.image-show {
      margin-bottom: 66px;
      height: calc(100vh - 220px);
    }

    .image-footer-1 {
      bottom: -110px;
      width: 180px;
    }
  }
}
</style>