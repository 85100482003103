var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pattern-block tutorial",class:[
    "size-1",
    { 'word-show': _vm.steps[_vm.step].qWord || !_vm.steps[_vm.step].aWord } ]},[_c('div',{staticClass:"description-box"},[_c('span',[_vm._v(_vm._s(_vm.steps[_vm.step].description))])]),(_vm.steps[_vm.step].qWord)?_c('div',{staticClass:"questtion-screen"},[_vm._v(" "+_vm._s(_vm.getWord(_vm.steps[_vm.step].qWord))+" ")]):_vm._e(),(_vm.steps[_vm.step].aWord)?_c('div',{staticClass:"title-box w-100"},[_c('p',{staticClass:"text-1",class:_vm.optionTypeWord},[_vm._v(" "+_vm._s(_vm.optionTypeWord === "th" ? "เลือกหนึ่งอย่าง" : "CHOOSE ONE")+" ")]),_c('p',{staticClass:"text-2"},[_vm._v(" "+_vm._s(_vm.optionTypeWord === "th" ? "คุณเคยเห็นคำนี้หรือไม่?" : "Have you ever seen this word?")+" ")])]):_vm._e(),(_vm.steps[_vm.step].aWord)?_c('div',{staticClass:"answer-screen",class:{
      fail: _vm.steps[_vm.step].correct === false,
      success: _vm.steps[_vm.step].correct === true,
    }},[_vm._v(" "+_vm._s(_vm.getWord(_vm.steps[_vm.step].aWord))+" ")]):_vm._e(),(_vm.steps[_vm.step].aWord)?_c('div',{staticClass:"answer-action"},[_c('el-button',{class:{
        'not-active': _vm.steps[_vm.step].buttonAction === false,
      },attrs:{"type":"primary","round":""}},[_vm._v(_vm._s(_vm.optionTypeWord === "th" ? "ใช่" : "Yes"))]),_c('el-button',{class:{
        'not-active': _vm.steps[_vm.step].buttonAction === true,
      },attrs:{"type":"primary","round":""}},[_vm._v(_vm._s(_vm.optionTypeWord === "th" ? "ไม่ใช่" : "No"))])],1):_vm._e(),(_vm.steps[_vm.step].qWord || !_vm.steps[_vm.step].aWord)?_c('img',{staticClass:"image-footer",attrs:{"src":require("../../assets/image/image-footer-2.png")}}):_vm._e(),_c('div',{staticClass:"control-step"},[_c('div',[(_vm.step > 0 && _vm.step < _vm.steps.length - 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.backStep}},[_c('i',{staticClass:"fas fa-angle-left"}),_vm._v(" "+_vm._s(_vm.$t("tutorial.btnBack")))]):_vm._e()],1),(_vm.step < _vm.steps.length - 1)?_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$emit('exitTutorial')}}},[_vm._v(_vm._s(_vm.$t("tutorial.btnSkip"))+" "),_c('i',{staticClass:"fas fa-angle-right"}),_c('i',{staticClass:"fas fa-angle-right"})]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.nextStep}},[_vm._v(_vm._s(_vm.$t("tutorial.btnNext"))+" "),_c('i',{staticClass:"fas fa-angle-right"})])],1):_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$emit('exitTutorial')}}},[_vm._v(_vm._s(_vm.$t("test.btnStartGame"))+" "),_c('i',{staticClass:"fas fa-angle-right"})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }