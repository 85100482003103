<template>
  <div
    class="pattern-block tutorial"
    :class="[
      `size-1`,
      { 'word-show': steps[step].qWord || !steps[step].aWord },
    ]"
  >
    <div class="description-box">
      <span>{{ steps[step].description }}</span>
    </div>

    <div v-if="steps[step].qWord" class="questtion-screen">
      {{ getWord(steps[step].qWord) }}
    </div>

    <div v-if="steps[step].aWord" class="title-box w-100">
      <p class="text-1" :class="optionTypeWord">
        {{ optionTypeWord === "th" ? "เลือกหนึ่งอย่าง" : "CHOOSE ONE" }}
      </p>
      <p class="text-2">
        {{
          optionTypeWord === "th"
            ? "คุณเคยเห็นคำนี้หรือไม่?"
            : "Have you ever seen this word?"
        }}
      </p>
    </div>

    <div
      v-if="steps[step].aWord"
      class="answer-screen"
      :class="{
        fail: steps[step].correct === false,
        success: steps[step].correct === true,
      }"
    >
      {{ getWord(steps[step].aWord) }}
    </div>

    <div v-if="steps[step].aWord" class="answer-action">
      <el-button
        type="primary"
        :class="{
          'not-active': steps[step].buttonAction === false,
        }"
        round
        >{{ optionTypeWord === "th" ? "ใช่" : "Yes" }}</el-button
      >

      <el-button
        type="primary"
        :class="{
          'not-active': steps[step].buttonAction === true,
        }"
        round
        >{{ optionTypeWord === "th" ? "ไม่ใช่" : "No" }}</el-button
      >
    </div>

    <img
      v-if="steps[step].qWord || !steps[step].aWord"
      class="image-footer"
      :src="require(`../../assets/image/image-footer-2.png`)"
    />

    <div class="control-step">
      <div>
        <el-button
          @click="backStep"
          v-if="step > 0 && step < steps.length - 1"
          type="text"
          ><i class="fas fa-angle-left"></i>
          {{ $t("tutorial.btnBack") }}</el-button
        >
      </div>
      <div v-if="step < steps.length - 1">
        <el-button @click="$emit('exitTutorial')" type="text"
          >{{ $t("tutorial.btnSkip") }} <i class="fas fa-angle-right"></i
          ><i class="fas fa-angle-right"></i
        ></el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button @click="nextStep" type="text"
          >{{ $t("tutorial.btnNext") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
      <div v-else>
        <el-button @click="$emit('exitTutorial')" type="text"
          >{{ $t("test.btnStartGame") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    optionTypeWord: {
      type: String,
      default: "",
    },
  },
  computed: {
    stimulsWord() {
      return this.$store.state.stimulsWord;
    },
  },
  data() {
    return {
      step: 0,
      steps: [
        {
          step: 0,
          description: this.$t("tutorialWordRecognition.step0"),
          countDown: null,
          lifePoints: 3,
          qWord: "1",
          aWord: "",
          start: null,
          correct: true,

          buttonAction: null,
        },
        {
          step: 1,
          description: this.$t("tutorialWordRecognition.step0"),
          countDown: 10,
          lifePoints: 3,
          qWord: "2",
          aWord: "",
          start: null,
          correct: true,

          buttonAction: null,
        },
        {
          step: 2,
          description: this.$t("tutorialWordRecognition.step0"),
          countDown: 10,
          lifePoints: 3,
          qWord: "3",
          aWord: "",
          start: null,
          correct: true,

          buttonAction: null,
        },
        {
          step: 3,
          description: this.$t("tutorialWordRecognition.step0"),
          countDown: null,
          lifePoints: 3,
          qWord: "",
          aWord: "3",
          start: true,
          correct: true,

          buttonAction: null,
        },
        {
          step: 4,
          description: this.$t("tutorialWordRecognition.step0"),
          countDown: null,
          lifePoints: 3,
          qWord: "",
          aWord: "5",
          start: null,
          correct: true,

          buttonAction: null,
        },
        {
          step: 5,
          description: this.$t("tutorialWordRecognition.step0"),
          countDown: null,
          lifePoints: 2,
          qWord: "",
          aWord: "5",
          start: null,
          correct: false,

          buttonAction: true,
        },
        {
          step: 6,
          description: this.$t("tutorialWordRecognition.step0"),
          countDown: null,
          lifePoints: 3,
          qWord: "",
          aWord: "3",
          start: null,
          correct: true,

          buttonAction: true,
        },

        {
          step: 7,
          description: this.$t("tutorialWordRecognition.step0"),
          countDown: null,
          lifePoints: 0,
          qWord: "",
          aWord: "2",
          start: null,
          correct: false,

          buttonAction: false,
        },
        {
          step: 8,
          description: this.$t("tutorialWordRecognition.step0"),
          countDown: null,
          lifePoints: 1,
          qWord: "",
          aWord: "9",
          start: null,
          correct: false,

          buttonAction: true,
        },
        {
          step: 9,
          description: this.$t("tutorialWordRecognition.step0"),
          countDown: null,
          lifePoints: 1,
          qWord: "8",
          aWord: "",
          start: null,
          correct: true,

          buttonAction: null,
        },
        {
          step: 10,
          description: this.$t("tutorialWordRecognition.step0"),
          countDown: null,
          lifePoints: 1,
          qWord: "",
          aWord: "8",
          start: null,
          correct: true,

          buttonAction: true,
        },
      ],
    };
  },

  methods: {
    nextStep() {
      this.step++;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    backStep() {
      this.step--;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    skip() {
      this.$emit("exitTutorial");
    },
    playStep() {
      if (this.steps[this.step].qWords.length > 0) {
        this.steps[this.step].qWords.map((item, index) => {
          setTimeout(() => {
            this.steps[this.step].qWord = item;
          }, 1000 * index);
        });
      }
    },
    getWord(id) {
      const find = this.stimulsWord.find((f) => f.id == id);

      if (typeof find !== "undefined") {
        if (this.optionTypeWord === "th") {
          return find.th;
        } else {
          return find.en;
        }
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 80px 40px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 63vh;
  margin-top: 0;

  .title-box {
    text-align: left;
    .text-1 {
      font-size: 12px;
      margin: 0;
      padding-bottom: 10px;
      &.th {
        font-size: 14px;
      }
    }
    .text-2 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      padding-bottom: 10px;
    }
  }
  &.word-show {
    margin-bottom: 390px;
    height: 30vh;
  }

  .image-footer {
    position: absolute;
    bottom: -330px;
    width: 400px;
    z-index: 99;
  }

  @media screen and (max-width: 1600px) {
    height: 55vh;

    &.word-show {
      margin-bottom: 280px;
      height: 25vh;
    }

    .image-footer {
      bottom: -270px;
      width: 350px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 52vh;
    &.word-show {
      margin-bottom: 230px;
      height: 25vh;
    }

    .image-footer {
      bottom: -225px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 53vh;
    &.word-show {
      margin-bottom: 230px;
      height: 25vh;
    }

    .image-footer {
      bottom: -230px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: 60vh;
    &.word-show {
      margin-bottom: 230px;
      height: 27vh;
    }

    .image-footer {
      bottom: -230px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 63vh;
    &.word-show {
      margin-bottom: 230px;
      height: 34vh;
    }

    .image-footer {
      bottom: -225px;
      width: 300px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 72vh;
    &.word-show {
      margin-bottom: 280px;
      height: 53vh;
    }

    .image-footer {
      bottom: -245px;
      width: 300px;
    }
  }

  @media screen and (max-width: 800px) {
    height: 70vh;

    &.word-show {
      margin-bottom: 280px;
      height: 49vh;
    }

    .image-footer {
      bottom: -245px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 40px 10px;
    height: calc(100vh - 180px);

    &.word-show {
      padding: 40px 0px;
      background-color: transparent;
      justify-content: center;
      margin-bottom: 165px;
      height: calc(100vh - 340px);
      .description-box {
        color: #fff;
      }

      .control-step {
        button {
          color: #fff;
        }
      }
    }

    .image-footer {
      bottom: -155px;
      width: 250px;
    }
  }
}
</style>