<template>
  <div>
    <div
      v-if="countDownStart > 3 && pattern"
      class="pattern-block"
      :class="`size-${pattern.size}`"
    >
      <div class="time-box">
        <div v-if="countDown" :style="gradient" class="time">
          <span>{{ countDown }}</span>
        </div>
      </div>
      <div v-for="row in pattern.size" :key="`row${row}`" class="row">
        <div
          v-for="column in pattern.size"
          :key="`column${column}`"
          class="column"
        >
          <div
            @click="reload ? null : touchBlock(row, column)"
            class="item"
            :class="[
              {
                mark: showMark && isMarked(row, column),
              },
              {
                marked: isAnswer(row, column),
              },
              {
                fail: isFail(row, column),
              },
              {
                disabled: reload,
              },
            ]"
          ></div>
        </div>
      </div>

      <div class="start-game">
        <el-button v-if="countDown" @click="play" type="primary">{{
          $t("test.btnStartGame")
        }}</el-button>
      </div>
    </div>
    <div v-else class="pattern-block">
      <CircleCountDown :countDownStart="countDownStart" />
    </div>
  </div>
</template>

<script>
import CircleCountDown from "@/components/game/CircleCountDown";

export default {
  components: {
    CircleCountDown,
  },
  props: {
    pattern: {
      type: Object,
      default: null,
    },
    lifePointsNow: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    gradient() {
      return {
        background: `conic-gradient(#FF9B57 0%, #FF9B57 ${
          this.countDown * 10
        }%, #FFDAC1 ${this.countDown * 10}%, #FFDAC1 0%)`,
      };
    },
  },
  watch: {
    pattern() {
      if (!this.gameMounted) {
        this.startGame(3);
      }
    },
    lifePoints() {
      this.$emit("setLifePoints", this.lifePoints);
    },
  },
  data() {
    return {
      showMark: true,
      answer: {
        row: null,
        column: null,
        correct: false,
        dateTime: null,
      },
      lifePoints: 3,
      reload: true,
      answers: [],
      startRound: null,
      countDown: null,
      myInterval: null,
      myTimeout: null,
      countDownStart: 0,
      gameMounted: true,
      timeStartStimuli: null,
      endStimuli: false,
      timeStartResponse: null,
      timeFirstClick: null,
      timeLastResponse: null,
      numResponseClicks: 0,
    };
  },
  mounted() {
    var countDownIntervalIdle = setInterval(() => {
      this.countDownStart++;
    }, 1000);

    setTimeout(() => {
      clearInterval(countDownIntervalIdle);
      this.countDownStart = 4;
      this.startGame(this.lifePointsNow);
      this.gameMounted = false;
    }, 4000);
  },
  methods: {
    startGame(lefe) {
      this.timeStartStimuli = new Date();
      this.numResponseClicks = 0;
      this.timeFirstClick = null;
      this.endStimuli = false;

      this.reload = true;
      this.answer = {
        row: null,
        column: null,
        correct: false,
      };
      this.lifePoints = lefe;
      this.answers = [];
      setTimeout(() => {
        this.showMark = true;
      }, 500);
      setTimeout(() => {
        this.countDown = 10;
        this.myInterval = setInterval(() => {
          this.countDown--;
        }, 1000);

        this.myTimeout = setTimeout(() => {
          clearInterval(this.myInterval);
          this.startRound = Date.now();
          this.countDown = null;
          this.reload = false;
          this.showMark = false;
          this.timeStartResponse = new Date();
        }, 10000);
      }, 1000);
    },
    play() {
      clearInterval(this.myInterval);
      clearTimeout(this.myTimeout);
      this.startRound = Date.now();
      this.countDown = null;
      this.reload = false;
      this.showMark = false;
      this.endStimuli = true;
      this.timeStartResponse = new Date();
    },
    isMarked(row, column) {
      if (!this.pattern) {
        return false;
      }

      let find = this.pattern.options[3 - this.lifePoints].positions.find(
        (f) => f.row == row && f.column == column
      );
      return typeof find !== "undefined" ? true : false;
    },
    touchBlock(row, column) {
      let isAnswer = this.isAnswer(row, column);
      if (isAnswer) {
        return false;
      }

      let indexOption = 3 - this.lifePoints;

      let find = this.pattern.options[indexOption].positions.find(
        (f) => f.row == row && f.column == column
      );

      this.numResponseClicks++;
      if (!this.timeFirstClick) {
        this.timeFirstClick = new Date();
      }
      if (typeof find !== "undefined") {
        this.answers.push({
          row: row,
          column: column,
          correct: true,
          dateTime: new Date(),
        });
        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          row: row,
          column: column,
          correct: true,
          time: Date.now() - this.startRound,
        });

        if (
          this.answers.length ==
          this.pattern.options[indexOption].positions.length
        ) {
          this.reload = true;
          this.timeLastResponse = new Date();

          this.$emit("setLogActions", {
            patternId: this.pattern._id,
            patternSize: this.pattern.size,
            trials: indexOption + 1,
            itemId: this.pattern.options[indexOption].id,
            answers: this.answers,
            timeFirstClick: this.timeFirstClick,
            correctAnswers: this.pattern.options[indexOption].positions,
            isCorrect: true,
            timeStartStimuli: this.timeStartStimuli,
            endStimuli: this.endStimuli,
            timeStartResponse: this.timeStartResponse,
            timeLastResponse: this.timeLastResponse,
            numResponseClicks: this.numResponseClicks,
            isPassTheLevel: true,
          });
          setTimeout(() => {
            this.$emit("goToLevel", "success");
          }, 1000);
        }
      } else {
        this.lifePoints--;
        this.reload = true;
        this.answer = {
          row: row,
          column: column,
          correct: false,
          dateTime: new Date(),
        };
        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          row: row,
          column: column,
          correct: false,
          time: Date.now() - this.startRound,
        });

        this.timeLastResponse = new Date();

        let actionAnswers = this.answers.concat(this.answer);

        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: indexOption + 1,
          itemId: this.pattern.options[indexOption].id,
          answers: actionAnswers,
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[indexOption].positions,
          isCorrect: false,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: this.endStimuli,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: false,
        });

        setTimeout(() => {
          this.answer = {
            row: null,
            column: null,
            correct: false,
          };
          if (this.lifePoints <= 0) {
            this.$emit("goToLevel", "fail");
          } else {
            setTimeout(() => {
              this.startGame(this.lifePoints);
            }, 500);
          }
        }, 500);
      }
    },
    isAnswer(row, column) {
      let find = this.answers.find((f) => f.row == row && f.column == column);

      return typeof find !== "undefined" ? true : false;
    },
    isFail(row, column) {
      if (
        this.answer.row == row &&
        this.answer.column == column &&
        !this.answer.correct
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  height: 74vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  @media screen and (max-width: 1600px) {
    height: 70vh;
  }

  @media screen and (max-width: 1280px) {
    height: 67vh;
  }

  @media screen and (max-width: 1199px) {
    height: 67vh;
  }

  @media screen and (max-width: 1100px) {
    height: 75vh;
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 150px);
  }
}
</style>