<template>
  <div>
    <div
      v-if="countDownStart > 3 && pattern"
      class="pattern-block"
      :class="[`size-${pattern.size}`, { 'word-show': qWord || !aWord }]"
    >
      <div v-if="qWord" class="questtion-screen">
        {{ getWord(qWord) }}
      </div>

      <div v-if="aWord" class="title-box w-100">
        <p class="text-1" :class="optionTypeWord">
          {{ optionTypeWord === "th" ? "เลือกหนึ่งอย่าง" : "CHOOSE ONE" }}
        </p>
        <p class="text-2">
          {{
            optionTypeWord === "th"
              ? "คุณเคยเห็นคำนี้หรือไม่?"
              : "Have you ever seen this word?"
          }}
        </p>
      </div>

      <div
        v-if="aWord"
        class="answer-screen"
        :class="{ fail: correct === false, success: correct === true }"
      >
        {{ getWord(aWord) }}
      </div>

      <div v-if="aWord" class="answer-action">
        <el-button
          @click="sendAnswer(true)"
          type="primary"
          :class="{
            'not-allow-click': reload,
            'not-active': buttonAction === false,
          }"
          round
          >{{ optionTypeWord === "th" ? "ใช่" : "Yes" }}</el-button
        >

        <el-button
          @click="sendAnswer(false)"
          type="primary"
          :class="{
            'not-allow-click': reload,
            'not-active': buttonAction === true,
          }"
          round
          >{{ optionTypeWord === "th" ? "ไม่ใช่" : "No" }}</el-button
        >
      </div>

      <img
        v-if="qWord || !aWord"
        class="image-footer"
        :src="require(`../../assets/image/image-footer-2.png`)"
      />
    </div>
    <div v-else class="pattern-block">
      <CircleCountDown :countDownStart="countDownStart" />
    </div>
  </div>
</template>

<script>
import CircleCountDown from "@/components/game/CircleCountDown";

export default {
  components: {
    CircleCountDown,
  },
  props: {
    pattern: {
      type: Object,
      default: null,
    },
    optionTypeWord: {
      type: String,
      default: "",
    },
    lifePointsNow: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    stimulsWord() {
      return this.$store.state.stimulsWord;
    },
  },
  watch: {
    pattern() {
      if (!this.gameMounted) {
        this.startGame(3);
      }
    },
    lifePoints() {
      this.$emit("setLifePoints", this.lifePoints);
    },
  },
  data() {
    return {
      answers: [],
      qWord: "",
      aWord: "",
      aIndex: 0,
      lifePoints: 3,
      reload: true,
      startRound: null,
      correct: null,
      start: false,
      buttonAction: null,
      countDownStart: 0,
      gameMounted: true,
      timeStartStimuli: null,
      timeStartResponse: null,
      timeFirstClick: null,
      timeLastResponse: null,
      numResponseClicks: 0,
      saveAnswers: [],
    };
  },
  mounted() {
    var countDownIntervalIdle = setInterval(() => {
      this.countDownStart++;
    }, 1000);

    setTimeout(() => {
      setTimeout(() => {
        clearInterval(countDownIntervalIdle);
      }, 1000);
      this.startGame(this.lifePointsNow);
      this.gameMounted = false;
    }, 4000);
  },
  methods: {
    playQ(index, positions) {
      if (index <= positions.length - 1) {
        let item = positions[index];
        let time = item ? 400 : 2400;
        setTimeout(() => {
          this.qWord = item;
          index++;
          this.playQ(index, positions);
        }, time);
      }
    },
    startGame(lefe) {
      this.timeStartStimuli = new Date();
      this.numResponseClicks = 0;
      this.timeFirstClick = null;
      this.saveAnswers = [];

      this.reload = true;
      this.lifePoints = lefe;
      this.correct = null;
      this.aIndex = 0;
      this.qWord = "";
      this.aWord = "";
      this.start = false;

      setTimeout(() => {
        let positions = [];
        let timout = 0;
        this.pattern.options[3 - this.lifePoints].positions.question.map(
          (item, index) => {
            if (index > 0) {
              positions.push("");
              timout += 400;
            }
            positions.push(item);
            timout += 2400;
          }
        );

        var index = 0;
        this.playQ(index, positions);

        setTimeout(() => {
          this.buttonAction = null;
          this.qWord = "";
          this.startRound = Date.now();
          this.reload = false;
          this.start = true;
          this.aWord =
            this.pattern.options[3 - this.lifePoints].positions.answer[0];
          this.timeStartResponse = new Date();
        }, timout);
      }, 500);
    },
    sendAnswer(status) {
      let indexOption = 3 - this.lifePoints;

      this.numResponseClicks++;
      if (!this.timeFirstClick) {
        this.timeFirstClick = new Date();
      }

      this.buttonAction = status;
      if (status) {
        this.correct =
          typeof this.pattern.options[indexOption].positions.question.find(
            (f) => f === this.aWord
          ) !== "undefined";
      } else {
        this.correct =
          typeof this.pattern.options[indexOption].positions.question.find(
            (f) => f === this.aWord
          ) === "undefined";
      }

      this.reload = true;

      this.saveAnswers.push({
        status: status,
        dateTime: new Date(),
        correct: this.correct,
      });

      if (this.correct === true) {
        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          answer: this.aWord,
          correct: true,
          time: Date.now() - this.startRound,
        });

        setTimeout(() => {
          this.buttonAction = null;
          this.correct = null;
          this.aIndex++;

          if (
            this.aIndex + 1 >
            this.pattern.options[3 - this.lifePoints].positions.answer.length
          ) {
            this.timeLastResponse = new Date();

            this.$emit("setLogActions", {
              patternId: this.pattern._id,
              patternSize: this.pattern.size,
              trials: indexOption + 1,
              itemId: this.pattern.options[indexOption].id,
              answers: this.saveAnswers,
              timeFirstClick: this.timeFirstClick,
              correctAnswers: this.pattern.options[indexOption].positions,
              isCorrect: true,
              timeStartStimuli: this.timeStartStimuli,
              endStimuli: null,
              timeStartResponse: this.timeStartResponse,
              timeLastResponse: this.timeLastResponse,
              numResponseClicks: this.numResponseClicks,
              isPassTheLevel: true,
              lang: this.optionTypeWord,
            });

            setTimeout(() => {
              this.$emit("goToLevel", "success");
              this.reload = false;
            }, 300);
          } else {
            this.aWord =
              this.pattern.options[3 - this.lifePoints].positions.answer[
                this.aIndex
              ];
            setTimeout(() => {
              this.reload = false;
            }, 300);
          }
        }, 500);
      } else {
        this.lifePoints--;

        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          answer: this.aWord,
          correct: false,
          time: Date.now() - this.startRound,
        });

        this.timeLastResponse = new Date();

        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: indexOption + 1,
          itemId: this.pattern.options[indexOption].id,
          answers: this.saveAnswers,
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[indexOption].positions,
          isCorrect: false,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: null,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: false,
          lang: this.optionTypeWord,
        });

        if (this.lifePoints <= 0) {
          setTimeout(() => {
            this.$emit("goToLevel", "fail");
          }, 500);
        } else {
          setTimeout(() => {
            this.startGame(this.lifePoints);
          }, 500);
        }
      }
    },
    getWord(id) {
      const find = this.stimulsWord.find((f) => f.id == id);

      if (typeof find !== "undefined") {
        if (this.optionTypeWord === "th") {
          return find.th;
        } else {
          return find.en;
        }
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 80px 40px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 63vh;
  margin-top: 0;

  .title-box {
    text-align: left;
    .text-1 {
      font-size: 12px;
      margin: 0;
      padding-bottom: 10px;
      &.th {
        font-size: 14px;
      }
    }
    .text-2 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      padding-bottom: 10px;
    }
  }
  &.word-show {
    margin-bottom: 390px;
    height: 30vh;
  }

  .image-footer {
    position: absolute;
    bottom: -330px;
    width: 400px;
    z-index: 99;
  }

  @media screen and (max-width: 1600px) {
    height: 55vh;

    &.word-show {
      margin-bottom: 280px;
      height: 25vh;
    }

    .image-footer {
      bottom: -270px;
      width: 350px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 52vh;
    &.word-show {
      margin-bottom: 230px;
      height: 25vh;
    }

    .image-footer {
      bottom: -225px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 53vh;
    &.word-show {
      margin-bottom: 230px;
      height: 25vh;
    }

    .image-footer {
      bottom: -230px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: 60vh;
    &.word-show {
      margin-bottom: 230px;
      height: 27vh;
    }

    .image-footer {
      bottom: -230px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 63vh;
    &.word-show {
      margin-bottom: 230px;
      height: 34vh;
    }

    .image-footer {
      bottom: -225px;
      width: 300px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 72vh;
    &.word-show {
      margin-bottom: 280px;
      height: 53vh;
    }

    .image-footer {
      bottom: -245px;
      width: 300px;
    }
  }

  @media screen and (max-width: 800px) {
    height: 70vh;

    &.word-show {
      margin-bottom: 280px;
      height: 49vh;
    }

    .image-footer {
      bottom: -245px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 40px 20px;
    height: calc(100vh - 185px);

    &.word-show {
      padding: 40px 0px;
      background-color: transparent;
      justify-content: center;
      margin-bottom: 165px;
      height: calc(100vh - 340px);
    }

    .image-footer {
      bottom: -155px;
      width: 250px;
    }
  }
}
</style>