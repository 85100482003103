<template>
  <div
    v-if="pattern"
    class="pattern-block"
    :class="[
      `size-${pattern.size}`,
      { 'image-show': showQ && !playAudio },
      { 'play-audio': playAudio },
    ]"
  >
    <img
      v-if="showQ && !playAudio"
      class="image-title"
      :src="require(`../../assets/image/image-title2.png`)"
    />

    <img
      v-if="showQ && playAudio"
      class="image-play"
      :src="require(`../../assets/image/play.png`)"
    />

    <div v-if="!playAudio" class="play-container">
      <div v-if="showQ">
        <img v-if="playAudio" :src="require(`../../assets/image/sound.gif`)" />
        <div @click="handlePlayAudio" v-else class="play">
          <img
            class="image-speak"
            :src="require(`../../assets/image/speak.png`)"
          />
          <p class="text">Press to play</p>
        </div>
      </div>
      <div v-else class="answer">
        <div class="title-box">
          <p class="text-1">Press your answer</p>
        </div>

        <div
          class="screen"
          :class="{ error: correct === false, success: correct === true }"
        >
          <div class="background">
            <div class="box">
              <div
                v-for="(item, index) in answer"
                :key="index"
                class="beep"
                :class="item === 'S' ? 'type-1' : 'type-2'"
                :id="`beep-${index}`"
              >
                <span>{{ index + 1 }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="action">
          <el-button @click="touchAnswer('S')" :disabled="reload" round
            ><div class="beep type-1"></div
          ></el-button>
          <el-button @click="touchAnswer('L')" :disabled="reload" round
            ><div class="beep type-2"></div
          ></el-button>
        </div>
        <div class="action-answer">
          <el-button
            @click="touchKey('Reset')"
            :loading="reload"
            plain
            :class="{
              'not-allow-click': reload,
              'not-active': answer.length === 0,
            }"
            round
            >{{ $t("test.btnSendReset") }}</el-button
          >

          <el-button
            @click="touchKey('Del')"
            :loading="reload"
            plain
            :class="{
              'not-allow-click': reload,
              'not-active': answer.length === 0,
            }"
            round
            >{{ $t("test.btnSendDelete") }}</el-button
          >

          <el-button
            @click="sendAnswer"
            :loading="reload"
            type="primary"
            :class="{
              'not-allow-click': reload,
              'not-active': answer.length === 0,
            }"
            round
            >{{ $t("test.btnSendAnswer") }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pattern: {
      type: Object,
      default: null,
    },
    optionType: {
      type: String,
      default: "",
    },
    lifePointsNow: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    pattern() {
      this.answer = [];
      this.playAudio = false;
      this.showQ = true;
      this.timeStart = new Date();
      this.lifePoints = 3;
    },
    lifePoints() {
      this.$emit("setLifePoints", this.lifePoints);
    },
  },
  data() {
    return {
      answer: [],
      lifePoints: 3,
      reload: true,
      startRound: null,
      correct: null,
      playAudio: false,
      showQ: true,
      timeStartStimuli: null,
      timeStartResponse: null,
      timeFirstClick: null,
      timeLastResponse: null,
      numResponseClicks: 0,
      responseAnswers: [],
      timeStart: null,
    };
  },
  mounted() {
    this.timeStart = new Date();
    this.lifePoints = this.lifePointsNow;
  },
  methods: {
    async playBeep1() {
      const audio = new Audio(require(`../../assets/video/censor-beep-1.mp3`));
      await audio.play();
    },
    async playBeep2() {
      const audio = new Audio(require(`../../assets/video/censor-beep-2.mp3`));
      await audio.play();
    },
    handlePlayAudio() {
      this.playAudio = true;
      this.startGame(this.lifePoints);
    },
    playQ(index, positions) {
      if (index <= positions.length - 1) {
        let item = positions[index];
        let time = index === 0 ? 500 : 1500;
        setTimeout(async () => {
          if (item === "S") {
            await this.playBeep1();
          } else {
            await this.playBeep2();
          }
          index++;
          this.playQ(index, positions);
        }, time);
      } else {
        setTimeout(() => {
          this.endQ();
        }, 1500);
      }
    },
    endQ() {
      this.playAudio = false;
      this.startRound = Date.now();
      this.reload = false;
      this.showQ = false;
      this.timeStartResponse = new Date();
    },
    startGame(lefe) {
      this.timeStartStimuli = new Date();
      this.numResponseClicks = 0;
      this.timeFirstClick = null;
      this.responseAnswers = [];

      this.reload = true;
      this.number = "";
      this.lifePoints = lefe;
      this.correct = null;

      var index = 0;
      this.playQ(index, this.pattern.options[3 - this.lifePoints].positions);
    },

    touchAnswer(type) {
      this.answer.push(type);
      setTimeout(() => {
        const element = document.getElementById(
          `beep-${this.answer.length - 1}`
        );

        if (element) {
          element.scrollIntoView();
        }
      }, 100);
      this.numResponseClicks++;
      if (!this.timeFirstClick) {
        this.timeFirstClick = new Date();
      }

      this.responseAnswers.push({ key: type, dateTime: new Date() });
    },
    touchKey(key) {
      if (key === "Del") {
        this.answer = this.answer.slice(0, this.answer.length - 1);
      } else {
        this.answer = [];
      }

      this.numResponseClicks++;
      if (!this.timeFirstClick) {
        this.timeFirstClick = new Date();
      }

      this.responseAnswers.push({ key: key, dateTime: new Date() });
    },
    sendAnswer() {
      let indexOption = 3 - this.lifePoints;
      let position = this.pattern.options[indexOption].positions.join("");
      let answer = this.answer.join("");

      this.responseAnswers.push({ key: "Send", dateTime: new Date() });

      if (position == answer) {
        this.timeLastResponse = new Date();
        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: indexOption + 1,
          itemId: this.pattern.options[indexOption].id,
          answers: {
            number: this.answer,
            dateTime: new Date(),
            correct: true,
          },
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[indexOption].positions,
          isCorrect: true,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: false,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: true,
          responseAnswers: this.responseAnswers,
          timeStart: this.timeStart,
        });

        setTimeout(() => {
          this.$emit("goToLevel", "success");
          this.reload = false;
        }, 500);

        this.correct = true;

        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          answer: answer,
          correct: true,
          time: Date.now() - this.startRound,
        });
      } else {
        this.reload = true;
        this.lifePoints--;
        this.correct = false;

        this.timeLastResponse = new Date();
        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: indexOption + 1,
          itemId: this.pattern.options[indexOption].id,
          answers: {
            number: this.answer,
            dateTime: new Date(),
            correct: false,
          },
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[indexOption].positions,
          isCorrect: false,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: false,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: false,
          responseAnswers: this.responseAnswers,
          timeStart: this.timeStart,
        });

        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          answer: answer,
          correct: false,
          time: Date.now() - this.startRound,
        });

        if (this.lifePoints <= 0) {
          this.$emit("goToLevel", "fail");
        } else {
          setTimeout(() => {
            this.answer = [];
            this.playAudio = false;
            this.showQ = true;
          }, 500);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 40px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 71vh;
  margin-top: 0;

  &.image-show {
    margin-top: 235px;
    height: 49vh;
  }

  &.play-audio {
    background: transparent;
  }

  .image-title {
    position: absolute;
    top: -225px;
    width: 300px;
    z-index: -1;
  }

  .title-box {
    text-align: left;
    .text-1 {
      font-size: 16px;
      margin: 0;
      padding-bottom: 10px;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1600px) {
    height: 65vh;

    &.image-show {
      margin-top: 215px;
      height: 40vh;
    }

    .image-title {
      top: -220px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 62vh;
    &.image-show {
      margin-top: 185px;
      height: 39vh;
    }

    .image-title {
      top: -190px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 62vh;
    &.image-show {
      margin-top: 185px;
      height: 39vh;
    }

    .image-title {
      top: -190px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: 70vh;
    &.image-show {
      margin-top: 185px;
      height: 44vh;
    }

    .image-title {
      top: -190px;
      width: 250px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 78vh;
    &.image-show {
      margin-top: 220px;
      height: 60vh;
    }

    .image-title {
      top: -215px;
      width: 300px;
    }
  }

  @media screen and (max-width: 800px) {
    height: 77vh;
    &.image-show {
      margin-top: 220px;
      height: 56vh;
    }

    .image-title {
      top: -215px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 155px);
    padding: 20px;

    &.image-show {
      margin-top: 100px;
      height: calc(100vh - 250px);
    }

    .image-title {
      top: -110px;
      width: 150px;
    }
  }
}
</style>