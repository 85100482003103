var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pattern-block tutorial small image-show",class:["size-2"]},[_c('div',{staticClass:"description-box"},[_c('span',[_vm._v(_vm._s(_vm.steps[_vm.step].description))])]),_c('img',{staticClass:"image-title",attrs:{"src":require("../../assets/image/image-title1.png")}}),_c('div',{staticClass:"row pb-1"},[_c('div',{staticClass:"column"},[(_vm.steps[_vm.step].countDown > 0)?_c('div',{staticClass:"item hide-box"},[_c('div',{staticClass:"time-box not-padding"},[_c('div',{staticClass:"time",style:(_vm.gradient)},[_c('span',[_vm._v(_vm._s(_vm.steps[_vm.step].countDown))])])])]):_vm._e(),(_vm.steps[_vm.step].countDown <= 0)?_c('div',{staticClass:"item",class:{
          fail: _vm.steps[_vm.step].fail,
          mark: _vm.steps[_vm.step].question && _vm.steps[_vm.step].question.mark,
        }},[(_vm.steps[_vm.step].question && !_vm.steps[_vm.step].question.mark)?_c('Stimul',{attrs:{"option":_vm.getOption(_vm.steps[_vm.step].question.number)}}):_vm._e()],1):_vm._e()])]),_vm._l((2),function(row){return _c('div',{key:("row" + row),staticClass:"row"},_vm._l((2),function(column){return _c('div',{key:("column" + column),staticClass:"column"},[_c('div',{staticClass:"item",class:{
          mark:
            _vm.steps[_vm.step].answer.length > 0 &&
            _vm.steps[_vm.step].answer[(row - 1) * 2 + column - 1].mark,
        }},[(
            _vm.steps[_vm.step].answer.length > 0 &&
            !_vm.steps[_vm.step].answer[(row - 1) * 2 + column - 1].mark
          )?_c('Stimul',{attrs:{"option":_vm.getOption(_vm.steps[_vm.step].answer[(row - 1) * 2 + column - 1].number)}}):_vm._e()],1)])}),0)}),_c('div',{staticClass:"start-game"},[(_vm.steps[_vm.step].countDown)?_c('el-button',{attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.$t("test.btnStartGame")))]):_vm._e()],1),_c('div',{staticClass:"control-step"},[_c('div',[(_vm.step > 0 && _vm.step < _vm.steps.length - 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.backStep}},[_c('i',{staticClass:"fas fa-angle-left"}),_vm._v(" "+_vm._s(_vm.$t("tutorial.btnBack")))]):_vm._e()],1),(_vm.step < _vm.steps.length - 1)?_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$emit('exitTutorial')}}},[_vm._v(" "+_vm._s(_vm.$t("tutorial.btnSkip"))+" "),_c('i',{staticClass:"fas fa-angle-right"}),_c('i',{staticClass:"fas fa-angle-right"})]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.$t("tutorial.btnNext"))+" "),_c('i',{staticClass:"fas fa-angle-right"})])],1):_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$emit('exitTutorial')}}},[_vm._v(_vm._s(_vm.$t("test.btnStartGame"))+" "),_c('i',{staticClass:"fas fa-angle-right"})])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }