<template>
  <div>
    <div
      v-if="countDownStart > 3 && pattern"
      class="pattern-block small image-show"
      :class="[`size-${pattern.size}`]"
    >
      <img
        class="image-title"
        :src="require(`../../assets/image/image-title1.png`)"
      />

      <div class="row pb-1">
        <div class="column">
          <div v-if="countDown > 0" class="item hide-box">
            <div class="time-box not-padding">
              <div :style="gradient" class="time">
                <span>{{ countDown }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="countDown <= 0"
            class="item"
            :class="{
              mark: !showQ,
              fail: answer.correct !== null && answer.correct === false,
            }"
          >
            <Stimul
              v-if="question && showQ"
              :option="getOption(question.number)"
            />
          </div>
        </div>
      </div>

      <div v-for="row in rows" :key="`row${row}`" class="row">
        <div
          v-for="column in pattern.size"
          :key="`column${column}`"
          class="column"
        >
          <div
            @click="reload ? null : touchBlock(row, column)"
            class="item"
            :class="{
              mark: !showA && !isAnswer(row, column),
              'visibility-hide': !getOption((row - 1) * pattern.size + column),
            }"
          >
            <Stimul
              v-if="showA || isAnswer(row, column)"
              :option="getOption((row - 1) * pattern.size + column)"
            />
          </div>
        </div>
      </div>
      <div class="start-game">
        <el-button
          v-if="countDown && countDown < 10"
          @click="play"
          type="primary"
          >{{ $t("test.btnStartGame") }}</el-button
        >
      </div>
    </div>
    <div v-else class="pattern-block">
      <CircleCountDown :countDownStart="countDownStart" />
    </div>
  </div>
</template>

<script>
import Stimul from "@/components/stimul/Stimul";
import CircleCountDown from "@/components/game/CircleCountDown";

export default {
  components: {
    Stimul,
    CircleCountDown,
  },
  props: {
    pattern: {
      type: Object,
      default: null,
    },
    lifePointsNow: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    stimuls() {
      return this.$store.state.stimuls;
    },
    question() {
      let item = this.pattern.options[this.indexOption].positions.find(
        (f) => f.correct
      );

      if (typeof item === "undefined") {
        return null;
      }

      return item;
    },
    gradient() {
      return {
        background: `conic-gradient(#FF9B57 0%, #FF9B57 ${
          this.countDown * 10
        }%, #FFDAC1 ${this.countDown * 10}%, #FFDAC1 0%)`,
      };
    },
    rows() {
      return Math.ceil(
        this.pattern.options[this.indexOption].positions.length /
          this.pattern.size
      );
    },
  },
  watch: {
    pattern() {
      if (!this.gameMounted) {
        this.startGame(3);
        this.endStimuli = false;
      }
    },
    lifePoints() {
      this.$emit("setLifePoints", this.lifePoints);
    },
  },
  data() {
    return {
      showQ: false,
      showA: true,
      answer: {
        number: null,
        stimul: null,
        correct: null,
      },
      lifePoints: 3,
      reload: true,
      startRound: null,
      countDown: 10,
      myInterval: null,
      myTimeout: null,
      indexOption: 0,
      countDownStart: 0,
      gameMounted: true,
      timeStartStimuli: null,
      endStimuli: false,
      timeStartResponse: null,
      timeFirstClick: null,
      timeLastResponse: null,
      numResponseClicks: 0,
    };
  },
  mounted() {
    var countDownIntervalIdle = setInterval(() => {
      this.countDownStart++;
    }, 1000);

    setTimeout(() => {
      setTimeout(() => {
        clearInterval(countDownIntervalIdle);
      }, 1000);
      this.startGame(this.lifePointsNow);
      this.gameMounted = false;
    }, 4000);
  },
  methods: {
    startGame(lefe) {
      this.timeStartStimuli = new Date();
      this.numResponseClicks = 0;
      this.timeFirstClick = null;
      this.endStimuli = false;

      this.reload = true;
      this.countDown = 10;
      this.lifePoints = lefe;
      this.answer = {
        number: null,
        stimul: null,
        correct: null,
      };

      this.showQ = false;
      this.showA = true;

      this.indexOption = 3 - lefe;

      setTimeout(() => {
        this.countDown = 9;
        this.myInterval = setInterval(() => {
          this.countDown--;
        }, 1000);

        this.myTimeout = setTimeout(() => {
          clearInterval(this.myInterval);
          this.startRound = Date.now();
          this.countDown = 0;
          this.reload = false;
          this.showQ = true;
          this.showA = false;
          this.timeStartResponse = new Date();
        }, 9000);
      }, 1000);
    },
    play() {
      clearInterval(this.myInterval);
      clearTimeout(this.myTimeout);
      this.startRound = Date.now();
      this.countDown = 0;
      this.reload = false;
      this.showQ = true;
      this.showA = false;
      this.endStimuli = true;
      this.timeStartResponse = new Date();
    },
    touchBlock(row, column) {
      let number = (row - 1) * this.pattern.size + column;

      let find = this.pattern.options[this.indexOption].positions.find(
        (f) => f.number === number
      );

      this.answer = {
        number: number,
        stimul: find.stimul,
        correct: find.correct,
        dateTime: new Date(),
      };
      this.reload = true;

      this.numResponseClicks++;
      if (!this.timeFirstClick) {
        this.timeFirstClick = new Date();
      }

      if (find.correct) {
        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[this.indexOption].id,
          number: number,
          stimul: find.stimul,
          correct: true,
          time: Date.now() - this.startRound,
        });

        this.timeLastResponse = new Date();

        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: this.indexOption + 1,
          itemId: this.pattern.options[this.indexOption].id,
          answers: [this.answer],
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[this.indexOption].positions,
          isCorrect: true,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: this.endStimuli,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: true,
        });

        setTimeout(() => {
          this.$emit("goToLevel", "success");
        }, 1000);
      } else {
        this.lifePoints--;
        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[this.indexOption].id,
          number: number,
          stimul: find.stimul,
          correct: false,
          time: Date.now() - this.startRound,
        });

        this.timeLastResponse = new Date();

        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: this.indexOption + 1,
          itemId: this.pattern.options[this.indexOption].id,
          answers: [this.answer],
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[this.indexOption].positions,
          isCorrect: false,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: this.endStimuli,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: false,
        });

        setTimeout(() => {
          if (this.lifePoints <= 0) {
            this.$emit("goToLevel", "fail");
          } else {
            setTimeout(() => {
              this.startGame(this.lifePoints);
            }, 500);
          }
        }, 500);
      }
    },
    isAnswer(row, column) {
      let number = (row - 1) * this.pattern.size + column;
      return this.answer.number === number;
    },
    getOption(number) {
      let item = this.pattern.options[this.indexOption].positions.find(
        (f) => f.number === number
      );

      if (typeof item === "undefined") {
        return null;
      }

      let find = this.stimuls.find((f) => f.id === item.stimul);

      if (typeof find === "undefined") {
        return null;
      }

      return find;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 20px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 76vh;
  margin-top: 0;

  &.image-show {
    margin-top: 105px;
    height: 66vh;
  }

  .image-title {
    position: absolute;
    top: -138px;
    width: 300px;
    z-index: -1;
  }

  @media screen and (max-width: 1600px) {
    height: 70vh;

    &.image-show {
      margin-top: 76px;
      height: 62vh;
    }

    .image-title {
      top: -115px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 68vh;
    &.image-show {
      margin-top: 68px;
      height: 60vh;
    }

    .image-title {
      top: -106px;
      width: 230px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 69vh;
    &.image-show {
      margin-top: 80px;
      height: 59vh;
    }

    .image-title {
      top: -115px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: 78vh;
    &.image-show {
      margin-top: 75px;
      height: 67vh;
    }

    .image-title {
      top: -115px;
      width: 250px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
    &.image-show {
      margin-top: 119px;
      height: 70vh;
    }

    .image-title {
      top: -138px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 135px);

    &.image-show {
      margin-top: 66px;
      height: calc(100vh - 200px);
    }

    .image-title {
      top: -83px;
      width: 180px;
    }
  }
}
</style>