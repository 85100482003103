<template>
  <div class="pattern-block tutorial small image-show" :class="[`size-2`]">
    <div class="description-box">
      <span>{{ steps[step].description }}</span>
    </div>

    <img
      class="image-title"
      :src="require(`../../assets/image/image-title1.png`)"
    />

    <div class="row pb-1">
      <div class="column">
        <div v-if="steps[step].countDown > 0" class="item hide-box">
          <div class="time-box not-padding">
            <div :style="gradient" class="time">
              <span>{{ steps[step].countDown }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="steps[step].countDown <= 0"
          class="item"
          :class="{
            fail: steps[step].fail,
            mark: steps[step].question && steps[step].question.mark,
          }"
        >
          <Stimul
            v-if="steps[step].question && !steps[step].question.mark"
            :option="getOption(steps[step].question.number)"
          />
        </div>
      </div>
    </div>

    <div v-for="row in 2" :key="`row${row}`" class="row">
      <div v-for="column in 2" :key="`column${column}`" class="column">
        <div
          class="item"
          :class="{
            mark:
              steps[step].answer.length > 0 &&
              steps[step].answer[(row - 1) * 2 + column - 1].mark,
          }"
        >
          <Stimul
            v-if="
              steps[step].answer.length > 0 &&
              !steps[step].answer[(row - 1) * 2 + column - 1].mark
            "
            :option="
              getOption(steps[step].answer[(row - 1) * 2 + column - 1].number)
            "
          />
        </div>
      </div>
    </div>

    <div class="start-game">
      <el-button v-if="steps[step].countDown" type="primary">{{
        $t("test.btnStartGame")
      }}</el-button>
    </div>

    <div class="control-step">
      <div>
        <el-button
          @click="backStep"
          v-if="step > 0 && step < steps.length - 1"
          type="text"
          ><i class="fas fa-angle-left"></i>
          {{ $t("tutorial.btnBack") }}</el-button
        >
      </div>
      <div v-if="step < steps.length - 1">
        <el-button @click="$emit('exitTutorial')" type="text">
          {{ $t("tutorial.btnSkip") }} <i class="fas fa-angle-right"></i
          ><i class="fas fa-angle-right"></i
        ></el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button @click="nextStep" type="text">
          {{ $t("tutorial.btnNext") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
      <div v-else>
        <el-button @click="$emit('exitTutorial')" type="text"
          >{{ $t("test.btnStartGame") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Stimul from "@/components/stimul/Stimul";

export default {
  components: {
    Stimul,
  },
  props: {
    pattern: {
      type: Object,
      default: null,
    },
  },
  computed: {
    stimuls() {
      return this.$store.state.stimuls;
    },
    gradient() {
      return {
        background: `conic-gradient(#FF9B57 0%, #FF9B57 ${
          this.steps[this.step].countDown * 10
        }%, #FFDAC1 ${this.steps[this.step].countDown * 10}%, #FFDAC1 0%)`,
      };
    },
  },

  data() {
    return {
      step: 0,
      steps: [
        {
          step: 0,
          description: this.$t("tutorialPairassociateslearning.step0"),
          countDown: null,
          lifePoints: 3,
          fail: false,
          question: null,
          answer: [],
        },
        {
          step: 1,
          description: this.$t("tutorialPairassociateslearning.step1"),
          countDown: 10,
          lifePoints: 3,
          fail: false,
          question: { number: 1, mark: true },
          answer: [
            { number: 1, mark: false },
            { number: 10, mark: false },
            { number: 5, mark: false },
            { number: 7, mark: false },
          ],
        },
        {
          step: 2,
          description: this.$t("tutorialPairassociateslearning.step2"),
          countDown: 10,
          lifePoints: 3,
          fail: false,
          question: { number: 1, mark: true },
          answer: [
            { number: 1, mark: false },
            { number: 10, mark: false },
            { number: 5, mark: false },
            { number: 7, mark: false },
          ],
        },
        {
          step: 3,
          description: this.$t("tutorialPairassociateslearning.step3"),
          countDown: 1,
          lifePoints: 3,
          fail: false,
          question: { number: 1, mark: true },
          answer: [
            { number: 1, mark: false },
            { number: 10, mark: false },
            { number: 5, mark: false },
            { number: 7, mark: false },
          ],
        },
        {
          step: 4,
          description: this.$t("tutorialPairassociateslearning.step4"),
          countDown: null,
          lifePoints: 3,
          fail: false,
          question: { number: 1, mark: false },
          answer: [
            { number: 1, mark: true },
            { number: 10, mark: true },
            { number: 5, mark: true },
            { number: 7, mark: true },
          ],
        },
        {
          step: 5,
          description: this.$t("tutorialPairassociateslearning.step5"),
          countDown: null,
          lifePoints: 3,
          fail: false,
          question: { number: 1, mark: false },
          answer: [
            { number: 1, mark: true },
            { number: 10, mark: true },
            { number: 5, mark: true },
            { number: 7, mark: true },
          ],
        },
        {
          step: 6,
          description: this.$t("tutorialPairassociateslearning.step6"),
          countDown: null,
          lifePoints: 2,
          fail: true,
          question: { number: 1, mark: false },
          answer: [
            { number: 1, mark: true },
            { number: 10, mark: false },
            { number: 5, mark: true },
            { number: 7, mark: true },
          ],
        },
        {
          step: 7,
          description: this.$t("tutorialPairassociateslearning.step7"),
          countDown: null,
          lifePoints: 3,
          fail: false,
          question: { number: 1, mark: false },
          answer: [
            { number: 1, mark: false },
            { number: 10, mark: true },
            { number: 5, mark: true },
            { number: 7, mark: true },
          ],
        },

        {
          step: 8,
          description: this.$t("tutorialPairassociateslearning.step8"),
          countDown: null,
          lifePoints: 0,
          fail: true,
          question: { number: 1, mark: false },
          answer: [
            { number: 1, mark: true },
            { number: 10, mark: true },
            { number: 5, mark: true },
            { number: 7, mark: false },
          ],
        },
        {
          step: 9,
          description: this.$t("tutorialPairassociateslearning.step9"),
          countDown: null,
          lifePoints: 1,
          fail: false,
          question: { number: 3, mark: true },
          answer: [
            { number: 5, mark: false },
            { number: 7, mark: false },
            { number: 3, mark: false },
            { number: 2, mark: false },
          ],
        },
        {
          step: 10,
          description: this.$t("tutorialPairassociateslearning.step10"),
          countDown: null,
          lifePoints: 1,
          fail: false,
          question: { number: 3, mark: false },
          answer: [
            { number: 5, mark: true },
            { number: 7, mark: true },
            { number: 3, mark: true },
            { number: 2, mark: true },
          ],
        },
        {
          step: 11,
          description: this.$t("tutorialPairassociateslearning.step11"),
          countDown: null,
          lifePoints: 1,
          fail: false,
          question: { number: 3, mark: false },
          answer: [
            { number: 5, mark: true },
            { number: 7, mark: true },
            { number: 3, mark: false },
            { number: 2, mark: true },
          ],
        },
      ],
    };
  },

  methods: {
    nextStep() {
      this.step++;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    backStep() {
      this.step--;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    skip() {
      this.$emit("exitTutorial");
    },
    getOption(number) {
      let find = this.stimuls.find((f) => f.id == number);

      if (typeof find === "undefined") {
        return null;
      }

      return find;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 20px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 76vh;
  margin-top: 0;

  &.image-show {
    margin-top: 130px;
    height: 64vh;
  }

  .image-title {
    position: absolute;
    top: -161px;
    width: 350px;
    z-index: -1;
  }

  @media screen and (max-width: 1600px) {
    height: 69vh;

    &.image-show {
      margin-top: 105px;
      height: 58vh;
    }

    .image-title {
      top: -138px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 68vh;
    &.image-show {
      margin-top: 80px;
      height: 58vh;
    }

    .image-title {
      top: -115px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 69vh;
    &.image-show {
      margin-top: 80px;
      height: 59vh;
    }

    .image-title {
      top: -115px;
      width: 250px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: 78vh;
    &.image-show {
      margin-top: 80px;
      height: 68vh;
    }

    .image-title {
      top: -115px;
      width: 250px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
    &.image-show {
      margin-top: 119px;
      height: 70vh;
    }

    .image-title {
      top: -138px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 115px);
    padding: 10px;

    &.image-show {
      margin-top: 66px;
      height: calc(100vh - 180px);
    }

    .image-title {
      top: -83px;
      width: 180px;
    }
  }
}
</style>