<template>
  <div class="circle-countDown">
    <div class="circle" :class="{ active: countDownStart >= 1 }"></div>
    <div class="circle" :class="{ active: countDownStart >= 2 }"></div>
    <div class="circle" :class="{ active: countDownStart >= 3 }"></div>
  </div>
</template>

<script>
export default {
  props: {
    countDownStart: {
      type: Number,
      default: 0,
    },
  },
};
</script>