<template>
  <div>
    <div
      v-if="countDownStart > 3 && pattern"
      class="pattern-block"
      :class="[`size-${pattern.size}`, { 'image-show': showQ }]"
    >
      <div v-if="showQ" class="qScreen">
        <div v-if="qNumber" class="box">
          {{ qNumber }}
        </div>
      </div>
      <div v-else class="numeric-container">
        <div class="numeric-screen">
          <el-input
            v-model="number"
            :class="{ error: correct === false, success: correct === true }"
            readonly
          >
            <font-awesome-icon
              class="el-input__icon hide"
              icon="fa-regular fa-circle-check "
              slot="prefix"
            />
            <font-awesome-icon
              v-if="correct === null"
              class="el-input__icon hide"
              icon="fa-regular fa-circle-check"
              slot="suffix"
            />

            <font-awesome-icon
              v-if="correct === true"
              icon="fa-regular fa-circle-check"
              class="el-input__icon"
              slot="suffix"
            />
            <font-awesome-icon
              v-if="correct === false"
              icon="fa-regular fa-circle-xmark"
              class="el-input__icon"
              slot="suffix"
            />
          </el-input>
        </div>
        <div class="numeric-keypad">
          <div
            v-for="(item, index) in numbers"
            :key="index"
            @click="touchNumber(item)"
            class="numeric-box"
            :class="{ hide: item === '' }"
          >
            <font-awesome-icon
              v-if="item === 'Reset'"
              icon="fa-solid fa-trash"
            />
            <font-awesome-icon
              v-else-if="item === 'Del'"
              icon="fa-solid fa-delete-left"
            />
            <span v-else>
              {{ item }}
            </span>
          </div>
        </div>
        <div class="text-center">
          <el-button
            @click="sendAnswer"
            type="primary"
            :loading="reload"
            class="w-100"
            round
            >{{ $t("test.btnSendAnswer") }}</el-button
          >
        </div>
      </div>
      <img
        v-if="showQ"
        class="image-footer-1"
        :src="require(`../../assets/image/image-footer-1.png`)"
      />
    </div>
    <div v-else class="pattern-block">
      <CircleCountDown :countDownStart="countDownStart" />
    </div>
  </div>
</template>

<script>
import CircleCountDown from "@/components/game/CircleCountDown";

export default {
  components: {
    CircleCountDown,
  },
  props: {
    pattern: {
      type: Object,
      default: null,
    },
    optionType: {
      type: String,
      default: "",
    },
    lifePointsNow: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    pattern() {
      if (!this.gameMounted) {
        this.startGame(3);
      }
    },
    lifePoints() {
      this.$emit("setLifePoints", this.lifePoints);
    },
  },
  data() {
    return {
      number: "",
      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, "Reset", 0, "Del"],
      qNumber: "",
      lifePoints: 3,
      reload: true,
      startRound: null,
      correct: null,
      showQ: true,
      countDownStart: 0,
      gameMounted: true,
      timeStartStimuli: null,
      timeStartResponse: null,
      timeFirstClick: null,
      timeLastResponse: null,
      numResponseClicks: 0,
      responseAnswers: [],
    };
  },
  mounted() {
    var countDownIntervalIdle = setInterval(() => {
      this.countDownStart++;
    }, 1000);

    setTimeout(() => {
      setTimeout(() => {
        clearInterval(countDownIntervalIdle);
      }, 1000);
      this.startGame(this.lifePointsNow);
      this.gameMounted = false;
    }, 4000);
  },
  methods: {
    touchNumber(n) {
      if (n === "Del") {
        this.number = this.number.slice(0, this.number.length - 1);
      } else if (n === "Reset") {
        this.number = "";
      } else {
        this.number += n;
      }

      this.numResponseClicks++;
      if (!this.timeFirstClick) {
        this.timeFirstClick = new Date();
      }

      this.responseAnswers.push({ key: n, dateTime: new Date() });
    },
    playQ(index, positions) {
      if (index <= positions.length - 1) {
        let item = positions[index];
        let time = item ? 400 : 1200;
        setTimeout(() => {
          this.qNumber = item;
          index++;
          this.playQ(index, positions);
        }, time);
      }
    },
    startGame(lefe) {
      this.timeStartStimuli = new Date();
      this.numResponseClicks = 0;
      this.timeFirstClick = null;
      this.responseAnswers = [];

      this.reload = true;

      this.number = "";
      this.lifePoints = lefe;
      this.correct = null;
      this.showQ = true;

      let positions = [];
      let timout = 0;
      this.pattern.options[3 - this.lifePoints].positions.map((item, index) => {
        if (index > 0) {
          positions.push("");
          timout += 400;
        }
        positions.push(item);
        timout += 1200;
      });

      var index = 0;
      this.playQ(index, positions);

      setTimeout(() => {
        this.qNumber = "";
        this.startRound = Date.now();
        this.reload = false;
        this.showQ = false;
        this.timeStartResponse = new Date();
      }, timout);
    },
    sendAnswer() {
      let indexOption = 3 - this.lifePoints;
      let aNumber = this.pattern.options[indexOption].positions
        .reverse()
        .join("");

      this.responseAnswers.push({ key: "Send", dateTime: new Date() });

      if (this.number == aNumber) {
        this.timeLastResponse = new Date();
        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: indexOption + 1,
          itemId: this.pattern.options[indexOption].id,
          answers: { number: this.number, dateTime: new Date(), correct: true },
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[indexOption].positions,
          isCorrect: true,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: null,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: true,
          responseAnswers: this.responseAnswers,
        });

        setTimeout(() => {
          this.$emit("goToLevel", "success");
          this.reload = false;
        }, 500);

        this.correct = true;

        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          answer: this.number,
          correct: true,
          time: Date.now() - this.startRound,
        });
      } else {
        this.reload = true;
        this.lifePoints--;
        this.correct = false;

        this.timeLastResponse = new Date();
        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: indexOption + 1,
          itemId: this.pattern.options[indexOption].id,
          answers: {
            number: this.number,
            dateTime: new Date(),
            correct: false,
          },
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[indexOption].positions,
          isCorrect: false,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: null,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: false,
          responseAnswers: this.responseAnswers,
        });

        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          answer: this.number,
          correct: false,
          time: Date.now() - this.startRound,
        });

        if (this.lifePoints <= 0) {
          this.$emit("goToLevel", "fail");
        } else {
          setTimeout(() => {
            this.startGame(this.lifePoints);
          }, 500);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  margin-top: 0;
  padding: 20px;

  .numeric-container {
    width: 290px;
  }
  &.image-show {
    margin-bottom: 175px;
    height: 59vh;
  }

  .image-footer-1 {
    position: absolute;
    bottom: -193px;
    right: 0;
    width: 400px;
    z-index: 99;
  }

  @media screen and (max-width: 1600px) {
    height: 69vh;

    &.image-show {
      margin-bottom: 175px;
      height: 50vh;
    }

    .image-footer-1 {
      bottom: -192px;
      width: 350px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 67vh;
    &.image-show {
      margin-bottom: 125px;
      height: 49vh;
    }

    .image-footer-1 {
      bottom: -168px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: calc(68vh - 30px);

    &.image-show {
      margin-bottom: 125px;
      height: 50vh;
    }

    .image-footer-1 {
      bottom: -170px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: calc(77vh - 30px);
    &.image-show {
      margin-bottom: 125px;
      height: 56vh;
    }

    .image-footer-1 {
      bottom: -164px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: calc(78vh - 30px);
    &.image-show {
      margin-bottom: 125px;
      height: 60vh;
    }

    .image-footer-1 {
      bottom: -164px;
      width: 300px;
    }
  }

  @media screen and (max-width: 918px) {
    height: calc(82vh - 30px);

    &.image-show {
      margin-bottom: 125px;
      height: 74vh;
    }

    .image-footer-1 {
      bottom: -161px;
      width: 300px;
    }
  }

  @media screen and (max-width: 700px) {
    height: 81vh;

    &.image-show {
      margin-bottom: 125px;
      height: 72vh;
    }

    .image-footer-1 {
      bottom: -160px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 135px);
    padding: 10px 20px;

    &.image-show {
      margin-bottom: 66px;
      height: calc(100vh - 220px);
    }

    .image-footer-1 {
      bottom: -110px;
      width: 180px;
    }
  }
}
</style>