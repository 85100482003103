<template>
  <div class="mode-box">
    <el-row :gutter="20" class="gutter-20">
      <el-col
        v-for="item in totalLevel"
        :key="item"
        :span="8"
        :sm="4"
        :md="3"
        :lg="2"
      >
        <div
          v-if="modeStore === 1"
          @click="
            item > playableLevel || lock ? null : $emit('startTest', item)
          "
          class="level-card"
          :class="{
            lock: item > playableLevel || lock,
          }"
        >
          <i v-if="item > topLevel || lock" class="fas fa-lock"></i>
          <p v-else>{{ item }}</p>
        </div>
        <div v-else @click="$emit('startTest', item)" class="level-card">
          <p>{{ item }}</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    totalLevel: {
      type: Number,
      default: 0,
    },
    topLevel: {
      type: Number,
      default: 0,
    },
    playableLevel: {
      type: Number,
      default: 1,
    },
    lock: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modeStore() {
      return this.$store.state.mode;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";

.mode-box {
  padding-top: 20px;
  .el-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.el-col {
  display: flex;
  align-items: center;
  justify-content: center;
  .level-card {
    border-radius: 4px;
    border: 1px solid $--color-blue-70;
    background-color: transparent;
    overflow: hidden;
    color: $--color-blue-60;
    transition: 0.3s;
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    p {
      margin: 0;
    }
    &.lock {
      background-color: $--color-blue-15;
      cursor: not-allowed;
    }
    &:hover {
      box-shadow: 0 2px 12px 0 #0000001a;
    }
  }
}
</style>
