var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.countDownStart > 3 && _vm.pattern)?_c('div',{staticClass:"pattern-block",class:("size-" + (_vm.pattern.size))},[_c('div',{staticClass:"time-box"},[(_vm.countDown)?_c('div',{staticClass:"time",style:(_vm.gradient)},[_c('span',[_vm._v(_vm._s(_vm.countDown))])]):_vm._e()]),_vm._l((_vm.pattern.size),function(row){return _c('div',{key:("row" + row),staticClass:"row"},_vm._l((_vm.pattern.size),function(column){return _c('div',{key:("column" + column),staticClass:"column"},[_c('div',{staticClass:"item",class:[
            {
              mark: _vm.showMark && _vm.isMarked(row, column),
            },
            {
              marked: _vm.isAnswer(row, column),
            },
            {
              fail: _vm.isFail(row, column),
            },
            {
              disabled: _vm.reload,
            } ],on:{"click":function($event){_vm.reload ? null : _vm.touchBlock(row, column)}}})])}),0)}),_c('div',{staticClass:"start-game"},[(_vm.countDown)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.play}},[_vm._v(_vm._s(_vm.$t("test.btnStartGame")))]):_vm._e()],1)],2):_c('div',{staticClass:"pattern-block"},[_c('CircleCountDown',{attrs:{"countDownStart":_vm.countDownStart}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }