<template>
  <div
    class="pattern-block tutorial"
    :class="[`size-1`, { 'image-show': steps[step].countDown }]"
  >
    <div class="description-box">
      <span>{{ steps[step].description }}</span>
    </div>

    <img
      v-if="steps[step].countDown"
      class="image-title"
      :src="require(`../../assets/image/image-title3.svg`)"
    />

    <div v-if="steps[step].countDown" class="time-box">
      <div :style="gradient" class="time">
        <span>{{ steps[step].countDown }}</span>
      </div>
    </div>

    <div
      class="color-span-container"
      :class="{ 'open-question': !steps[step].showMark }"
    >
      <div v-if="steps[step].showMark" class="question height">
        <div class="body">
          <div
            v-for="(item, index) in steps[step].questions"
            :key="index"
            class="item"
            :style="`background-color:${getColorCode(item)};`"
          ></div>
        </div>
      </div>
      <div v-else>
        <div
          class="question"
          :class="{
            fail: steps[step].correct === false,
            success: steps[step].correct === true,
          }"
        >
          <div class="body">
            <div
              v-for="(item, index) in steps[step].questions"
              :key="index"
              class="item"
              :class="{ active: getAnswer(index) }"
              :style="`background-color:${getAnswer(
                index
              )};border-color:${getAnswer(index)};`"
            >
              <span :style="`${getTextColor(index)}`">{{ index + 1 }}</span>
            </div>
          </div>
        </div>
        <el-row :gutter="10" class="answer">
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('orange')};`"
            >
              <span :style="`color:#fff;`">Orange</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('yellow')};`"
            >
              <span :style="`color:#fff;`">Yellow</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('green')};`"
            >
              <span :style="`color:#fff;`">Green</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('skyblue')};`"
            >
              <span :style="`color:#fff;`">Blue</span>
            </div></el-col
          >

          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('purple')};`"
            >
              <span :style="`color:#fff;`">Purple</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('red')};`"
            >
              <span :style="`color:#fff;`">Red</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('black')};`"
            >
              <span :style="`color:#fff;`">Black</span>
            </div></el-col
          >
          <el-col :span="6"
            ><div
              class="item"
              :style="`background-color:${getColorCode('white')};`"
            >
              <span :style="`color:#8E8E8E;`">White</span>
            </div></el-col
          >
        </el-row>

        <div class="action-answer">
          <el-button
            plain
            :class="{
              'not-active': steps[step].answers.length === 0,
            }"
            round
            >{{ $t("test.btnSendReset") }}</el-button
          >

          <el-button
            plain
            :class="{
              'not-active': steps[step].answers.length === 0,
            }"
            round
            >{{ $t("test.btnSendDelete") }}</el-button
          >

          <el-button
            type="primary"
            :class="{
              'not-active':
                steps[step].answers.length !== steps[step].questions.length,
            }"
            round
            >{{ $t("test.btnSendAnswer") }}</el-button
          >
        </div>
      </div>

      <div v-if="steps[step].countDown" class="start-game">
        <el-button type="primary">{{ $t("test.btnStartGame") }}</el-button>
      </div>
    </div>

    <div class="control-step">
      <div>
        <el-button
          @click="backStep"
          v-if="step > 0 && step < steps.length - 1"
          type="text"
          ><i class="fas fa-angle-left"></i>
          {{ $t("tutorial.btnBack") }}</el-button
        >
      </div>
      <div v-if="step < steps.length - 1">
        <el-button @click="$emit('exitTutorial')" type="text"
          >{{ $t("tutorial.btnSkip") }} <i class="fas fa-angle-right"></i
          ><i class="fas fa-angle-right"></i
        ></el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button @click="nextStep" type="text"
          >{{ $t("tutorial.btnNext") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
      <div v-else>
        <el-button @click="$emit('exitTutorial')" type="text"
          >{{ $t("test.btnStartGame") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    gradient() {
      return {
        background: `conic-gradient(#FF9B57 0%, #FF9B57 ${
          this.steps[this.step].countDown * 10
        }%, #FFDAC1 ${this.steps[this.step].countDown * 10}%, #FFDAC1 0%)`,
      };
    },
  },
  data() {
    return {
      step: 0,
      steps: [
        {
          step: 0,
          description: this.$t("tutorialColorSpan.step0"),
          countDown: 10,
          lifePoints: 3,
          showMark: true,
          questions: ["skyblue", "purple", "red"],
          answers: [],
          correct: null,
        },
        {
          step: 1,
          description: this.$t("tutorialColorSpan.step1"),
          countDown: 7,
          lifePoints: 3,
          showMark: true,
          questions: ["skyblue", "purple", "red"],
          answers: [],
          correct: null,
        },
        {
          step: 2,
          description: this.$t("tutorialColorSpan.step2"),
          countDown: 4,
          lifePoints: 3,
          showMark: true,
          questions: ["skyblue", "purple", "red"],
          answers: [],
          correct: null,
        },
        {
          step: 3,
          description: this.$t("tutorialColorSpan.step3"),
          countDown: 1,
          lifePoints: 3,
          showMark: true,
          questions: ["skyblue", "purple", "red"],
          answers: [],
          correct: null,
        },
        {
          step: 4,
          description: this.$t("tutorialColorSpan.step4"),
          countDown: null,
          lifePoints: 3,
          showMark: false,
          questions: ["skyblue", "purple", "red"],
          answers: [],
          correct: null,
        },
        {
          step: 5,
          description: this.$t("tutorialColorSpan.step5"),
          countDown: null,
          lifePoints: 3,
          showMark: false,
          questions: ["skyblue", "purple", "red"],
          answers: [],
          correct: null,
        },
        {
          step: 6,
          description: this.$t("tutorialColorSpan.step6"),
          countDown: null,
          lifePoints: 2,
          showMark: false,
          questions: ["skyblue", "purple", "red"],
          answers: ["purple", "skyblue", "red"],
          correct: false,
        },
        {
          step: 7,
          description: this.$t("tutorialColorSpan.step7"),
          countDown: null,
          lifePoints: 0,
          showMark: false,
          questions: ["skyblue", "purple", "red"],
          answers: ["red", "skyblue", "purple"],
          correct: false,
        },
        {
          step: 8,
          description: this.$t("tutorialColorSpan.step8"),
          countDown: 10,
          lifePoints: 1,
          showMark: true,
          questions: ["yellow", "purple", "skyblue"],
          answers: [],
          correct: true,
        },
        {
          step: 9,
          description: this.$t("tutorialColorSpan.step9"),
          countDown: 6,
          lifePoints: 1,
          showMark: true,
          questions: ["yellow", "purple", "skyblue"],
          answers: [],
          correct: true,
        },
        {
          step: 10,
          description: this.$t("tutorialColorSpan.step10"),
          countDown: null,
          lifePoints: 1,
          showMark: false,
          questions: ["yellow", "purple", "skyblue"],
          answers: ["yellow", "purple", "skyblue"],
          correct: true,
        },
      ],
    };
  },

  methods: {
    nextStep() {
      this.step++;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    backStep() {
      this.step--;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    skip() {
      this.$emit("exitTutorial");
    },
    getAnswer(index) {
      if (typeof this.steps[this.step].answers[index] !== "undefined") {
        return this.getColorCode(this.steps[this.step].answers[index]);
      }

      return "";
    },
    getTextColor(index) {
      if (
        this.steps[this.step].answers[index] === "skyblue" ||
        this.steps[this.step].answers[index] === "purple" ||
        this.steps[this.step].answers[index] === "red" ||
        this.steps[this.step].answers[index] === "black"
      ) {
        return "color: #fff;opacity: 1;";
      } else {
        return "color: #606266;opacity: 0.6;";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 40px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 71vh;
  margin-top: 0;

  &.image-show {
    margin-top: 205px;
    height: 52vh;
    justify-content: center;
  }

  &.play-audio {
    background: transparent;
  }

  .image-title {
    position: absolute;
    top: -225px;
    width: 300px;
    z-index: -1;
  }

  .title-box {
    text-align: left;
    .text-1 {
      font-size: 16px;
      margin: 0;
      padding-bottom: 10px;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1600px) {
    height: 65vh;

    &.image-show {
      margin-top: 205px;
      height: 41vh;
    }

    .image-title {
      top: -230px;
      width: 350px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 62vh;
    &.image-show {
      margin-top: 170px;
      height: 41vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 64vh;
    &.image-show {
      margin-top: 170px;
      height: 43vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1100px) {
    height: 71vh;
    &.image-show {
      margin-top: 169px;
      height: 47vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 73vh;
    &.image-show {
      margin-top: 170px;
      height: 51vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 79vh;
    &.image-show {
      margin-top: 178px;
      height: 64vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 800px) {
    height: 77vh;
    &.image-show {
      margin-top: 172px;
      height: 61vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 115px);
    padding: 10px;

    &.image-show {
      margin-top: 100px;
      height: calc(100vh - 250px);
    }

    .image-title {
      top: -120px;
      width: 180px;
    }
  }
}
</style>
