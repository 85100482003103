<template>
  <div>
    <div
      v-if="countDownStart > 3 && pattern"
      class="pattern-block"
      :class="[`size-${pattern.size}`, { 'image-show': countDown }]"
    >
      <img
        v-if="countDown"
        class="image-title"
        :src="require(`../../assets/image/image-title3.svg`)"
      />

      <div v-if="countDown" class="time-box">
        <div :style="gradient" class="time">
          <span>{{ countDown }}</span>
        </div>
      </div>
      <div
        class="color-span-container"
        :class="{ 'open-question': !showMark, full: fullScreen }"
      >
        <div v-if="showMark" class="question height">
          <div class="body">
            <div
              v-for="(item, index) in questions"
              :key="index"
              class="item"
              :style="`background-color:${getColorCode(item)};`"
            ></div>
          </div>
        </div>
        <div v-else>
          <div
            class="question"
            :class="{ fail: correct === false, success: correct === true }"
          >
            <div class="body">
              <div
                v-for="(item, index) in questions"
                :key="index"
                :id="`color-${index}`"
                class="item"
                :class="{ active: getAnswer(index) }"
                :style="`background-color:${getAnswer(
                  index
                )};border-color:${getAnswer(index)}`"
              >
                <span :style="`${getTextColor(index)}`">{{ index + 1 }} </span>
              </div>
            </div>
          </div>
          <el-row :gutter="10" class="answer">
            <el-col :span="6"
              ><div
                @click="touchAnswer('orange')"
                class="item"
                :style="`background-color:${getColorCode('orange')};`"
              >
                <span :style="`color:#fff;`">Orange</span>
              </div></el-col
            >
            <el-col :span="6"
              ><div
                @click="touchAnswer('yellow')"
                class="item"
                :style="`background-color:${getColorCode('yellow')};`"
              >
                <span :style="`color:#fff;`">Yellow</span>
              </div></el-col
            >
            <el-col :span="6"
              ><div
                @click="touchAnswer('green')"
                class="item"
                :style="`background-color:${getColorCode('green')};`"
              >
                <span :style="`color:#fff;`">Green</span>
              </div></el-col
            >
            <el-col :span="6"
              ><div
                @click="touchAnswer('skyblue')"
                class="item"
                :style="`background-color:${getColorCode('skyblue')};`"
              >
                <span :style="`color:#fff;`">Blue</span>
              </div></el-col
            >

            <el-col :span="6"
              ><div
                @click="touchAnswer('purple')"
                class="item"
                :style="`background-color:${getColorCode('purple')};`"
              >
                <span :style="`color:#fff;`">Purple</span>
              </div></el-col
            >
            <el-col :span="6"
              ><div
                @click="touchAnswer('red')"
                class="item"
                :style="`background-color:${getColorCode('red')};`"
              >
                <span :style="`color:#fff;`">Red</span>
              </div></el-col
            >
            <el-col :span="6"
              ><div
                @click="touchAnswer('black')"
                class="item"
                :style="`background-color:${getColorCode('black')};`"
              >
                <span :style="`color:#fff;`">Black</span>
              </div></el-col
            >
            <el-col :span="6"
              ><div
                @click="touchAnswer('white')"
                class="item"
                :style="`background-color:${getColorCode('white')};`"
              >
                <span :style="`color:#8E8E8E;`">White</span>
              </div></el-col
            >
          </el-row>

          <div class="action-answer">
            <el-button
              @click="touchKey(`Reset`)"
              :loading="reload"
              plain
              :class="{
                'not-allow-click': reload,
                'not-active': answers.length === 0,
              }"
              round
              >{{ $t("test.btnSendReset") }}</el-button
            >

            <el-button
              @click="touchKey(`Del`)"
              :loading="reload"
              plain
              :class="{
                'not-allow-click': reload,
                'not-active': answers.length === 0,
              }"
              round
              >{{ $t("test.btnSendDelete") }}</el-button
            >

            <el-button
              @click="sendAnswer"
              :loading="reload"
              type="primary"
              :class="{
                'not-allow-click': reload,
                'not-active': answers.length !== questions.length,
              }"
              round
              >{{ $t("test.btnSendAnswer") }}</el-button
            >
          </div>
        </div>

        <div v-if="countDown" class="start-game">
          <el-button @click="play" type="primary">{{
            $t("test.btnStartGame")
          }}</el-button>
        </div>
      </div>
    </div>
    <div v-else class="pattern-block">
      <CircleCountDown :countDownStart="countDownStart" />
    </div>
  </div>
</template>

<script>
import "@/service/other";
import CircleCountDown from "@/components/game/CircleCountDown";

export default {
  components: {
    CircleCountDown,
  },
  props: {
    pattern: {
      type: Object,
      default: null,
    },
    lifePointsNow: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    gradient() {
      return {
        background: `conic-gradient(#FF9B57 0%, #FF9B57 ${
          this.countDown * 10
        }%, #FFDAC1 ${this.countDown * 10}%, #FFDAC1 0%)`,
      };
    },
    fullScreen() {
      if (!this.pattern) {
        return false;
      }

      if (this.sizeScreen > 1600 && this.pattern.level >= 13) {
        return true;
      } else if (this.sizeScreen <= 1600 && this.pattern.level >= 9) {
        return true;
      } else if (this.sizeScreen <= 1280 && this.pattern.level >= 7) {
        return true;
      } else if (this.sizeScreen <= 1199 && this.pattern.level >= 7) {
        return true;
      } else if (this.sizeScreen <= 991 && this.pattern.level >= 4) {
        return true;
      } else if (this.sizeScreen <= 767 && this.pattern.level >= 2) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    pattern() {
      if (!this.gameMounted) {
        this.startGame(3);
      }
    },
    lifePoints() {
      this.$emit("setLifePoints", this.lifePoints);
    },
  },
  data() {
    return {
      showMark: true,
      lifePoints: 3,
      reload: true,
      answers: [],
      startRound: null,
      countDown: null,
      myInterval: null,
      myTimeout: null,
      questions: [],
      correct: null,
      sizeScreen: window.innerWidth,
      countDownStart: 0,
      gameMounted: true,
      timeStartStimuli: null,
      endStimuli: false,
      timeStartResponse: null,
      timeFirstClick: null,
      timeLastResponse: null,
      numResponseClicks: 0,
      responseAnswers: [],
    };
  },
  mounted() {
    var countDownIntervalIdle = setInterval(() => {
      this.countDownStart++;
    }, 1000);

    setTimeout(() => {
      setTimeout(() => {
        clearInterval(countDownIntervalIdle);
      }, 1000);
      this.startGame(this.lifePointsNow);
      this.gameMounted = false;
    }, 4000);

    window.addEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      this.sizeScreen = window.innerWidth;
    },
    startGame(lefe) {
      this.timeStartStimuli = new Date();
      this.numResponseClicks = 0;
      this.timeFirstClick = null;
      this.responseAnswers = [];
      this.endStimuli = false;

      this.reload = true;
      this.lifePoints = lefe;

      this.questions = this.pattern.options[3 - this.lifePoints].positions;
      this.correct = null;
      this.showMark = true;
      this.answers = [];

      this.countDown = 10;
      this.myInterval = setInterval(() => {
        this.countDown--;
      }, 1000);

      this.myTimeout = setTimeout(() => {
        clearInterval(this.myInterval);
        this.startRound = Date.now();
        this.countDown = null;
        this.reload = false;
        this.showMark = false;
        this.timeStartResponse = new Date();
      }, 10000);
    },
    play() {
      clearInterval(this.myInterval);
      clearTimeout(this.myTimeout);
      this.startRound = Date.now();
      this.countDown = null;
      this.reload = false;
      this.showMark = false;
      this.endStimuli = true;
      this.timeStartResponse = new Date();
    },
    touchAnswer(color) {
      if (this.answers.length < this.questions.length) {
        this.answers.push(color);

        const element = document.getElementById(
          `color-${this.answers.length - 1}`
        );

        if (element) {
          element.scrollIntoView();
        }

        this.numResponseClicks++;
        if (!this.timeFirstClick) {
          this.timeFirstClick = new Date();
        }

        this.responseAnswers.push({ key: color, dateTime: new Date() });
      }
    },
    touchKey(key) {
      if (key === "Del") {
        this.answers = this.answers.slice(0, this.answers.length - 1);
      } else {
        this.answers = [];
      }

      this.numResponseClicks++;
      if (!this.timeFirstClick) {
        this.timeFirstClick = new Date();
      }

      this.responseAnswers.push({ key: key, dateTime: new Date() });
    },
    getAnswer(index) {
      if (typeof this.answers[index] !== "undefined") {
        return this.getColorCode(this.answers[index]);
      }

      return "";
    },
    sendAnswer() {
      let indexOption = 3 - this.lifePoints;
      let questions = this.questions.join();
      let answers = this.answers.join();

      this.responseAnswers.push({ key: "Send", dateTime: new Date() });
      if (questions == answers) {
        this.timeLastResponse = new Date();
        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: indexOption + 1,
          itemId: this.pattern.options[indexOption].id,
          answers: {
            number: this.answers,
            dateTime: new Date(),
            correct: true,
          },
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[indexOption].positions,
          isCorrect: true,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: this.endStimuli,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: true,
          responseAnswers: this.responseAnswers,
        });

        setTimeout(() => {
          this.$emit("goToLevel", "success");
          this.reload = false;
        }, 500);

        this.correct = true;

        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          answer: answers,
          correct: true,
          time: Date.now() - this.startRound,
        });
      } else {
        this.reload = true;
        this.lifePoints--;
        this.correct = false;

        this.timeLastResponse = new Date();
        this.$emit("setLogActions", {
          patternId: this.pattern._id,
          patternSize: this.pattern.size,
          trials: indexOption + 1,
          itemId: this.pattern.options[indexOption].id,
          answers: {
            number: this.answers,
            dateTime: new Date(),
            correct: false,
          },
          timeFirstClick: this.timeFirstClick,
          correctAnswers: this.pattern.options[indexOption].positions,
          isCorrect: false,
          timeStartStimuli: this.timeStartStimuli,
          endStimuli: this.endStimuli,
          timeStartResponse: this.timeStartResponse,
          timeLastResponse: this.timeLastResponse,
          numResponseClicks: this.numResponseClicks,
          isPassTheLevel: false,
          responseAnswers: this.responseAnswers,
        });

        this.$emit("setAnswers", {
          patternId: this.pattern._id,
          optionId: this.pattern.options[indexOption].id,
          answer: answers,
          correct: false,
          time: Date.now() - this.startRound,
        });

        setTimeout(() => {
          if (this.lifePoints <= 0) {
            this.$emit("goToLevel", "fail");
          } else {
            this.startGame(this.lifePoints);
          }
        }, 500);
      }
    },
    getTextColor(index) {
      if (
        this.answers[index] === "skyblue" ||
        this.answers[index] === "purple" ||
        this.answers[index] === "red" ||
        this.answers[index] === "black"
      ) {
        return "color: #fff;opacity: 1;";
      } else {
        return "color: #606266;opacity: 0.6;";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  padding: 40px;
  min-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 71vh;
  margin-top: 0;

  &.image-show {
    margin-top: 205px;
    height: 52vh;
    justify-content: center;
  }

  &.play-audio {
    background: transparent;
  }

  .image-title {
    position: absolute;
    top: -225px;
    width: 300px;
    z-index: -1;
  }

  .title-box {
    text-align: left;
    .text-1 {
      font-size: 16px;
      margin: 0;
      padding-bottom: 10px;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1600px) {
    height: 65vh;

    &.image-show {
      margin-top: 205px;
      height: 41vh;
    }

    .image-title {
      top: -230px;
      width: 350px;
    }
  }

  @media screen and (max-width: 1280px) {
    height: 62vh;
    &.image-show {
      margin-top: 170px;
      height: 41vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1199px) {
    height: 62vh;
    &.image-show {
      margin-top: 170px;
      height: 42vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }
  @media screen and (max-width: 1100px) {
    height: 71vh;
    &.image-show {
      margin-top: 169px;
      height: 47vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 73vh;
    &.image-show {
      margin-top: 170px;
      height: 51vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 918px) {
    height: 77vh;
    &.image-show {
      margin-top: 178px;
      height: 61vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 800px) {
    height: 77vh;
    &.image-show {
      margin-top: 172px;
      height: 61vh;
    }

    .image-title {
      top: -195px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 155px);
    padding: 20px;
    justify-content: center;
    &.image-show {
      margin-top: 100px;
      height: calc(100vh - 250px);
    }

    .image-title {
      top: -120px;
      width: 180px;
    }
  }
}
</style>