var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pattern)?_c('div',{staticClass:"pattern-block",class:[
    ("size-" + (_vm.pattern.size)),
    { 'image-show': _vm.showQ && !_vm.playAudio },
    { 'play-audio': _vm.playAudio } ]},[(_vm.showQ && !_vm.playAudio)?_c('img',{staticClass:"image-title",attrs:{"src":require("../../assets/image/image-title2.png")}}):_vm._e(),(_vm.showQ && _vm.playAudio)?_c('img',{staticClass:"image-play",attrs:{"src":require("../../assets/image/play.png")}}):_vm._e(),(!_vm.playAudio)?_c('div',{staticClass:"play-container"},[(_vm.showQ)?_c('div',[(_vm.playAudio)?_c('img',{attrs:{"src":require("../../assets/image/sound.gif")}}):_c('div',{staticClass:"play",on:{"click":_vm.handlePlayAudio}},[_c('img',{staticClass:"image-speak",attrs:{"src":require("../../assets/image/speak.png")}}),_c('p',{staticClass:"text"},[_vm._v("Press to play")])])]):_c('div',{staticClass:"answer"},[_vm._m(0),_c('div',{staticClass:"screen",class:{ error: _vm.correct === false, success: _vm.correct === true }},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"box"},_vm._l((_vm.answer),function(item,index){return _c('div',{key:index,staticClass:"beep",class:item === 'S' ? 'type-1' : 'type-2',attrs:{"id":("beep-" + index)}},[_c('span',[_vm._v(_vm._s(index + 1))])])}),0)])]),_c('div',{staticClass:"action"},[_c('el-button',{attrs:{"disabled":_vm.reload,"round":""},on:{"click":function($event){return _vm.touchAnswer('S')}}},[_c('div',{staticClass:"beep type-1"})]),_c('el-button',{attrs:{"disabled":_vm.reload,"round":""},on:{"click":function($event){return _vm.touchAnswer('L')}}},[_c('div',{staticClass:"beep type-2"})])],1),_c('div',{staticClass:"action-answer"},[_c('el-button',{class:{
            'not-allow-click': _vm.reload,
            'not-active': _vm.answer.length === 0,
          },attrs:{"loading":_vm.reload,"plain":"","round":""},on:{"click":function($event){return _vm.touchKey('Reset')}}},[_vm._v(_vm._s(_vm.$t("test.btnSendReset")))]),_c('el-button',{class:{
            'not-allow-click': _vm.reload,
            'not-active': _vm.answer.length === 0,
          },attrs:{"loading":_vm.reload,"plain":"","round":""},on:{"click":function($event){return _vm.touchKey('Del')}}},[_vm._v(_vm._s(_vm.$t("test.btnSendDelete")))]),_c('el-button',{class:{
            'not-allow-click': _vm.reload,
            'not-active': _vm.answer.length === 0,
          },attrs:{"loading":_vm.reload,"type":"primary","round":""},on:{"click":_vm.sendAnswer}},[_vm._v(_vm._s(_vm.$t("test.btnSendAnswer")))])],1)])]):_vm._e()]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('p',{staticClass:"text-1"},[_vm._v("Press your answer")])])}]

export { render, staticRenderFns }