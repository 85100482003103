<template>
  <div class="pattern-block tutorial" :class="`size-3`">
    <div class="description-box">
      <span>{{ steps[step].description }}</span>
    </div>

    <div v-for="row in 3" :key="`row${row}`" class="row">
      <div v-for="column in 3" :key="`column${column}`" class="column">
        <div
          class="item"
          :class="[
            {
              mark: isMarked(row, column),
            },
            {
              fail: isFail(row, column),
            },
          ]"
        ></div>
      </div>
    </div>

    <div class="control-step">
      <div>
        <el-button
          @click="backStep"
          v-if="step > 0 && step < steps.length - 1"
          type="text"
          ><i class="fas fa-angle-left"></i>
          {{ $t("tutorial.btnBack") }}</el-button
        >
      </div>
      <div v-if="step < steps.length - 1">
        <el-button @click="$emit('exitTutorial')" type="text">
          {{ $t("tutorial.btnSkip") }} <i class="fas fa-angle-right"></i
          ><i class="fas fa-angle-right"></i
        ></el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button @click="nextStep" type="text">
          {{ $t("tutorial.btnNext") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
      <div v-else>
        <el-button @click="$emit('exitTutorial')" type="text"
          >{{ $t("test.btnStartGame") }} <i class="fas fa-angle-right"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pattern: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      step: 0,
      steps: [
        {
          step: 0,
          positions: [],
          description: this.$t("tutorialSpatialSpanSequence.step0"),

          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
        },
        {
          step: 1,
          positions: [{ row: 1, column: 3 }],
          description: this.$t("tutorialSpatialSpanSequence.step1"),

          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
        },
        {
          step: 2,
          positions: [{ row: 2, column: 1 }],
          description: this.$t("tutorialSpatialSpanSequence.step2"),

          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
        },
        {
          step: 3,
          positions: [],
          description: this.$t("tutorialSpatialSpanSequence.step3"),

          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
        },
        {
          step: 4,
          positions: [],
          description: this.$t("tutorialSpatialSpanSequence.step4"),

          lifePoints: 3,
          fail: {
            row: null,
            column: null,
          },
        },
        {
          step: 5,
          positions: [],
          description: this.$t("tutorialSpatialSpanSequence.step5"),

          lifePoints: 2,
          fail: {
            row: 1,
            column: 2,
          },
        },
        {
          step: 6,
          positions: [],
          description: this.$t("tutorialSpatialSpanSequence.step6"),

          lifePoints: 0,
          fail: {
            row: 2,
            column: 2,
          },
        },
        {
          step: 7,
          positions: [{ row: 3, column: 1 }],
          description: this.$t("tutorialSpatialSpanSequence.step7"),

          lifePoints: 2,
          fail: {
            row: null,
            column: null,
          },
        },
        {
          step: 8,
          positions: [{ row: 2, column: 2 }],
          description: this.$t("tutorialSpatialSpanSequence.step8"),

          lifePoints: 2,
          fail: {
            row: null,
            column: null,
          },
        },
        {
          step: 9,
          positions: [{ row: 2, column: 2 }],
          description: this.$t("tutorialSpatialSpanSequence.step9"),

          lifePoints: 2,
          fail: {
            row: null,
            column: null,
          },
        },
      ],
    };
  },
  methods: {
    nextStep() {
      this.step++;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    backStep() {
      this.step--;
      this.$emit("setLifePoints", this.steps[this.step].lifePoints);
    },
    skip() {
      this.$emit("exitTutorial");
    },
    isMarked(row, column) {
      let find = this.steps[this.step].positions.find(
        (f) => f.row == row && f.column == column
      );
      return typeof find !== "undefined" ? true : false;
    },
    isFail(row, column) {
      if (
        this.steps[this.step].fail.row == row &&
        this.steps[this.step].fail.column == column &&
        !this.steps[this.step].fail.correct
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-block {
  background-color: #fff;
  border-radius: 32px;
  height: 74vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  @media screen and (max-width: 1600px) {
    height: 70vh;
  }

  @media screen and (max-width: 1280px) {
    height: 67vh;
  }

  @media screen and (max-width: 1199px) {
    height: 67vh;
  }

  @media screen and (max-width: 1100px) {
    height: 74vh;
  }

  @media screen and (max-width: 918px) {
    height: 81vh;
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 150px);
    padding: 10px;
  }
}
</style>