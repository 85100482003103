var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pattern-block tutorial",class:["size-1", { 'image-show': _vm.steps[_vm.step].countDown }]},[_c('div',{staticClass:"description-box"},[_c('span',[_vm._v(_vm._s(_vm.steps[_vm.step].description))])]),(_vm.steps[_vm.step].countDown)?_c('img',{staticClass:"image-title",attrs:{"src":require("../../assets/image/image-title3.svg")}}):_vm._e(),(_vm.steps[_vm.step].countDown)?_c('div',{staticClass:"time-box"},[_c('div',{staticClass:"time",style:(_vm.gradient)},[_c('span',[_vm._v(_vm._s(_vm.steps[_vm.step].countDown))])])]):_vm._e(),_c('div',{staticClass:"color-span-container",class:{ 'open-question': !_vm.steps[_vm.step].showMark }},[(_vm.steps[_vm.step].showMark)?_c('div',{staticClass:"question height"},[_c('div',{staticClass:"body"},_vm._l((_vm.steps[_vm.step].questions),function(item,index){return _c('div',{key:index,staticClass:"item",style:(("background-color:" + (_vm.getColorCode(item)) + ";"))})}),0)]):_c('div',[_c('div',{staticClass:"question",class:{
          fail: _vm.steps[_vm.step].correct === false,
          success: _vm.steps[_vm.step].correct === true,
        }},[_c('div',{staticClass:"body"},_vm._l((_vm.steps[_vm.step].questions),function(item,index){return _c('div',{key:index,staticClass:"item",class:{ active: _vm.getAnswer(index) },style:(("background-color:" + (_vm.getAnswer(
              index
            )) + ";border-color:" + (_vm.getAnswer(index)) + ";"))},[_c('span',{style:(("" + (_vm.getTextColor(index))))},[_vm._v(_vm._s(index + 1))])])}),0)]),_c('el-row',{staticClass:"answer",attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"item",style:(("background-color:" + (_vm.getColorCode('orange')) + ";"))},[_c('span',{style:("color:#fff;")},[_vm._v("Orange")])])]),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"item",style:(("background-color:" + (_vm.getColorCode('yellow')) + ";"))},[_c('span',{style:("color:#fff;")},[_vm._v("Yellow")])])]),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"item",style:(("background-color:" + (_vm.getColorCode('green')) + ";"))},[_c('span',{style:("color:#fff;")},[_vm._v("Green")])])]),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"item",style:(("background-color:" + (_vm.getColorCode('skyblue')) + ";"))},[_c('span',{style:("color:#fff;")},[_vm._v("Blue")])])]),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"item",style:(("background-color:" + (_vm.getColorCode('purple')) + ";"))},[_c('span',{style:("color:#fff;")},[_vm._v("Purple")])])]),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"item",style:(("background-color:" + (_vm.getColorCode('red')) + ";"))},[_c('span',{style:("color:#fff;")},[_vm._v("Red")])])]),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"item",style:(("background-color:" + (_vm.getColorCode('black')) + ";"))},[_c('span',{style:("color:#fff;")},[_vm._v("Black")])])]),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"item",style:(("background-color:" + (_vm.getColorCode('white')) + ";"))},[_c('span',{style:("color:#8E8E8E;")},[_vm._v("White")])])])],1),_c('div',{staticClass:"action-answer"},[_c('el-button',{class:{
            'not-active': _vm.steps[_vm.step].answers.length === 0,
          },attrs:{"plain":"","round":""}},[_vm._v(_vm._s(_vm.$t("test.btnSendReset")))]),_c('el-button',{class:{
            'not-active': _vm.steps[_vm.step].answers.length === 0,
          },attrs:{"plain":"","round":""}},[_vm._v(_vm._s(_vm.$t("test.btnSendDelete")))]),_c('el-button',{class:{
            'not-active':
              _vm.steps[_vm.step].answers.length !== _vm.steps[_vm.step].questions.length,
          },attrs:{"type":"primary","round":""}},[_vm._v(_vm._s(_vm.$t("test.btnSendAnswer")))])],1)],1),(_vm.steps[_vm.step].countDown)?_c('div',{staticClass:"start-game"},[_c('el-button',{attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.$t("test.btnStartGame")))])],1):_vm._e()]),_c('div',{staticClass:"control-step"},[_c('div',[(_vm.step > 0 && _vm.step < _vm.steps.length - 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.backStep}},[_c('i',{staticClass:"fas fa-angle-left"}),_vm._v(" "+_vm._s(_vm.$t("tutorial.btnBack")))]):_vm._e()],1),(_vm.step < _vm.steps.length - 1)?_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$emit('exitTutorial')}}},[_vm._v(_vm._s(_vm.$t("tutorial.btnSkip"))+" "),_c('i',{staticClass:"fas fa-angle-right"}),_c('i',{staticClass:"fas fa-angle-right"})]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.nextStep}},[_vm._v(_vm._s(_vm.$t("tutorial.btnNext"))+" "),_c('i',{staticClass:"fas fa-angle-right"})])],1):_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$emit('exitTutorial')}}},[_vm._v(_vm._s(_vm.$t("test.btnStartGame"))+" "),_c('i',{staticClass:"fas fa-angle-right"})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }