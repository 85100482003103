import { render, staticRenderFns } from "./ColorSpan.vue?vue&type=template&id=656139a2&scoped=true&"
import script from "./ColorSpan.vue?vue&type=script&lang=js&"
export * from "./ColorSpan.vue?vue&type=script&lang=js&"
import style0 from "./ColorSpan.vue?vue&type=style&index=0&id=656139a2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "656139a2",
  null
  
)

export default component.exports